ion-content {
  &.concept-detail {
    .header-banner {
      position: relative;

      .banner-img {
        width: 100vw;
        height: 75vh;
        max-height: 75vh;
        max-width: none;
        object-fit: cover;
      }

      .content {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: auto;
        z-index: 1;
        width: 100%;
        height: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 48px;
        padding-left: 48px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;


        .content-text {
          max-width: 1248px;
          margin: 0 auto;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          padding: 80px 360px 80px 0;
          display: flex;
          align-items: center;

          h1,
          p {
            padding: 0 24px;
            text-shadow: 0 1px 8px var(--ion-color-medium);
          }

          p {
            font: {
              size: 1.55rem
            }
          }
        }
      }
    }

    ion-grid {
      &.light {
        .subtitle {
          color: var(--ion-text-color);
        }

        &.has-followed-card {
          min-height: 200px;
        }

        ion-col {
          &.card-col {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            right: 8px;
            top: 0;
            position: absolute;

            .followed-card {
              z-index: 2;
              margin-top: -240px;
            }

            .share-with-text {
              display: flex;
              align-items: center;
              justify-content: space-between;
              max-width: 420px;
              width: 100%;
              margin-top: -40px;
            }

            @media (max-width: 1216px) {
              right: 0;
            }

            @media only screen and (max-width: 767px) {
              align-items: center;
              position: unset;

              .followed-card {
                margin-top: 40px;
              }
            }
          }

          .max-w-900 {
            max-width: 800px;
            margin: 0;
          }
        }

        ion-row {
          &.search-location {
            max-width: 800px;
            margin: 0;

            .date {
              font-size: 12px;
              line-height: 18px;
              color: var(--ion-color-medium);
            }

            ion-row {
              ion-col {
                &.search-result-card {
                  padding: 0;
                  box-shadow: none;
                  background-color: unset;

                  font: {
                    family: $fontRegular;
                    size: 24px;
                  }

                  color: var(--ion-color-dark);
                  line-height: 30px;

                  .search-result-title,
                  .count {
                    font: {
                      family: $fontRegular;
                      size: 24px;
                    }

                    color: var(--ion-color-dark);
                    line-height: 30px;
                    border: none;
                  }
                  .like {
                    width: 42px;
                    height: 42px;
                    border-radius: 42px;
                    border: none;

                    ion-icon {
                      font-size: 34px;
                    }

                    .badge {
                      width: 18px;
                      height: 18px;
                      border-radius: 18px;
                      top: 2px;
                      right: -2px;
                      font-size: 10px;
                      animation-delay: 0.5s;
                    }
                  }

                  @media screen and (max-width: 768px) {
                    font: {
                      size: 18px;
                    }

                    line-height: 20px;

                    .count {
                      font: {
                        size: 18px;
                      }

                      line-height: 20px;
                    }
                  }
                }
              }
            }

            @media screen and (max-width: 768px) {
              .subtitle {
                font-size: 18px !important;
                line-height: 25px !important;
              }
            }
          }
        }
      }

      &.dark {
        width: 100%;
        padding: 24px;
        background: var(--ion-color-tertiary);

        .subtitle {
          color: var(--ion-text-color);
        }
      }

      ion-row {
        &.max-w-1200 {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0;
        }

        &.max-w-1248 {
          max-width: 1248px;
          margin: 0 auto;
          padding: 0;
        }

        &.max-w-1216 {
          max-width: 1216px;
          margin: 0 auto;
          padding: 0;

          &.card-loop-detail {
            ion-col {
              padding: 24px;
            }
          }

          @media only screen and (max-width: 992px) {
            padding-left: 8px !important;
            padding-right: 8px !important;
          }
        }


        &.search {
          max-width: 900px;
          padding: 0;

          .subtitle {
            margin: 0 0 0 16px;
            font-size: 1.2em;
          }
        }

        h3 {
          font: {
            family: $fontBold;
            size: 1.45em;
          }
        }

        @media only screen and (max-width: 767px) {
          &.search {
            .subtitle {
              margin: 0 0 16px 0;

              .subtitle {
                font-size: 16px;
              }
            }
          }

          .subtitle {
            font-size: 16px !important;
            line-height: 20px !important;
          }
        }

        ion-col {
          &.link {
            display: flex;
            justify-content: right;
          }

          &.search-result-card {
            margin-bottom: 12px;
            padding: 8px 12px 8px 16px;
            border-radius: 4px;
            background-color: var(--ion-color-light);
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%), 0 1px 3px 0 rgb(0 0 0 / 10%);

            font: {
              family: $fontBold;
              size: 1.15em;
            }

            .count {
              width: 42px;
              height: 42px;
              border-radius: 42px;
              border: 2px solid var(--ion-color-light-shade);

              font: {
                family: $fontMedium;
                size: 1em;
              }
            }

            .like {
              width: 42px;
              height: 42px;
              border-radius: 42px;
              border: 2px solid var(--ion-color-primary);
              position: relative;

              background: initial;

              ion-icon {
                color: var(--ion-color-primary);
                font-size: 1.5em;
                padding-top: 2px;
                pointer-events: none
              }

              &.liked {
                background: var(--ion-color-primary);

                ion-icon {
                  color: var(--ion-color-light);
                }
              }

              .badge {
                background: var(--ion-color-primary);
                color: var(--ion-color-light);
                width: 24px;
                height: 24px;
                border-radius: 24px;
                position: absolute;
                top: -5px;
                right: -10px;
                display: none;

                font: {
                  family: $fontMedium;
                  size: 12px;
                }
              }

              &:hover {
                .badge {
                  display: flex;
                }
              }
            }
          }

          @media only screen and (max-width: 767px) {
            &.link {
              justify-content: left;
              order: 1;
              padding: 0 24px 0 0;

              p {
                margin: 0;
              }
            }

            &.title {
              padding: 0;

            }
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      .header-banner {
        .content {
          .content-text {
            padding: 80px 24px 80px 24px;

            h1,
            p {
              padding: 0;
            }
          }
        }
      }

      ion-grid {
        &.light {
          padding: 0;
        }

        &.dark {
          padding: 24px 0;

          .max-w-1200 {
            padding: 0 16px !important;
          }
        }
      }
    }
  }
}

ion-row {
  &.title {
    &.default {
      ion-col {
        padding: 0 0 10px 0;
      }
    }
  }
}

.swiper-grid {
  .swiper-div {
    padding: 0 8px 8px 8px;

    .swipe {
      margin-top: 4px;
      cursor: pointer;
      position: relative;
      transition: box-shadow .2s, transform .3s, -webkit-transform .3s;

      &:hover {
        box-shadow: 0 8px 20px 0 rgb(0 0 0 / 6%), 0 8px 16px 0 rgb(0 0 0 / 10%);
        transform: translate(0, -4px);
      }


      img {
        display: block;
        width: 100%;
        height: 240px !important;
        object-fit: cover;
        border-radius: 4px;
        max-height: 246px !important;


        @media (max-width: 992px) {
          height: 60vw !important;
        }
      }

      .swiper-title {
        position: absolute;
        bottom: 18px;
        left: 0;
        right: 0;

        font-family: $fontBold;
        font-size: 1.4rem;
        line-height: 1.3em;
        text-shadow: 0 1px 6px var(--ion-color-black);
        color: var(--ion-color-light);
        text-align: left;
        margin: 0;
        padding: 0 8px 0 16px;

        @media (max-width: 992px) {
          bottom: 12px;

          line-height: 1.4em;
          font-size: 1.2rem;
        }
      }
    }
  }
}


//Group-detai
ion-content {
  &.group-detail {
    --background: var(--ion-background-color);

    app-header-desktop {
      ion-grid {
        &.desktop {
          padding-bottom: 0 !important;
        }
      }
    }
  }
}

ion-grid {
  &.header-group-detail {
    ion-row {
      ion-col {
        &.background {
          padding-right: 32px;

          img {
            min-height: 324px;
            height: 100%;
            width: 100%;
            border-radius: 10px;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            -o-object-fit: cover;
            object-fit: cover;

          }

          &.news-item {
            padding-right: 0;
            height: 100%;

            img {
              height: 500px;
            }

            @media (max-width: 992px) {
              padding-right: 24px;
            }
          }

          @media (max-width: 992px) {
            padding: 0 0 10px 0;

            img {
              border-radius: 10px;
            }

            &.news-item {
              img {
                height: 324px;
              }
            }
          }
        }

        app-breadcrumbs ion-breadcrumbs {
          margin-bottom: 10px;

          @media (max-width: 992px) {
            margin-bottom: 0;
          }
        }

        .content-header {
          padding: 0;
          position: relative;
          width: 100%;
          height: 100%;
          grid-auto-columns: 1fr;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          grid-row-start: span 1;
          grid-row-end: span 1;
          grid-column-start: span 1;
          grid-column-end: span 1;
          justify-content: space-between;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            padding-bottom: 8px;
            font-size: 14px;

            font: {
              family: $fontRegular;
            }

            color: var(--ion-color-medium);

            a {
              color: var(--ion-color-medium);
              text-decoration: initial;
            }
          }

          h1 {
            margin: 0;
            padding-bottom: 5px;

            @media (min-width: 1250px) {
              font-size: 2.6rem;
            }

            @media (min-width: 992px) and (max-width: 1250px) {
              font-size: 2.1rem;
            }
          }

          h3 {
            margin: 0;
            padding-bottom: 8px;
            line-height: 28px;
            max-width: 320px;

            font: {
              family: $fontRegular;
              size: 24px;
            }

          ;
          }

          .info {
            .item {
              padding: 4px 0;

              font: {
                family: $fontMedium;
                size: 14px;
              }

              line-height: 20px;
              color: var(--ion-color-gray);

              ion-icon {
                color: var(--ion-color-gray);
                font-size: 20px;
                vertical-align: sub;
                padding: 2px 12px 0 0;
              }

              span {
                font: {
                  family: $fontBold;
                  size: 14px;
                }

                color: var(--ion-text-color);
              }
            }
          }

          .info-foot {
            padding-top: 10px;

            .item {
              font: {
                family: $fontMedium;
                size: 14px;
              }

              line-height: 20px;
              color: #585858;

              a {
                color: var(--ion-color-medium);
                font-size: 20px;
                line-height: 20px;
                margin-right: 8px;

                :hover {
                  color: var(--ion-text-color);
                }

                ion-icon {
                  font-size: 20px;
                  cursor: pointer;
                  vertical-align: middle;
                }
              }
            }

            .footer {
              z-index: 2;
              padding: 16px 0 5px 0;
              position: relative;
              width: 100%;

              .list {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;

                .list-item {
                  margin-right: 0.5em;
                  margin-bottom: 0.5em;
                  border: 1px solid rgba(0, 0, 0, .05);
                  border-radius: 4px;
                  background-color: var(--ion-color-tertiary);
                  padding: 0.33em 0.5em 0.3em;
                  line-height: 1.2em;
                  text-align: center;
                  color: var(--ion-color-gray);
                  text-decoration: none;

                  font: {
                    family: $fontMedium;
                    size: 14px;
                  }

                  ion-select {
                    padding: 0;

                    &::part(text) {
                      display: none;
                    }

                    &::part(icon) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.detail-sticky-header {
    // overflow-x: auto;
    z-index: 9999;
    background: var(--ion-background-color);
    padding: 20px 0 0 0;
    margin-top: -3px;
    margin-bottom: 40px;
    // border-bottom: 2px solid #F2F2F2;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    @media (max-width: 767px) {
      // border-width: 1px 0px;
      // border-style: solid;
      // border-color: rgba(0, 0, 0, 0.15);
      // border-top: 2px solid #F2F2F2;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      margin-bottom: 10px;
    }

    ion-row {
      padding: 0 24px;

      ion-col {
        padding: 5px 0 0 0;

        .item {
          margin: 0 15px;
          padding-bottom: 0;
          text-decoration: none;
          color: var(--ion-color-medium);

          &:first-child {
            margin-left: 0;

            &.active {
              margin-left: 0;
            }
          }

          p {
            font: {
              family: $fontRegular;
              size: 18px;
            }

            line-height: 20px;
            padding: 2px 0;
            margin: 0;
            border-radius: 6px;
          }

          &.active {
            border-bottom: 2px solid;

            p {
              font: {
                family: $fontBold;
                size: 18px;
              }
            }

            &.first {
              border-bottom: none !important;
            }

            &.second {
              border-bottom: none !important;
            }

            &.third {
              border-bottom: none !important;
            }
          }

          .btn-update {
            padding: 6px 10px;
            min-width: max-content;
            font-size: 14px;
          }

          .btn-update-big {
            padding: 0 10px;
            min-width: max-content;
            font-size: 16px;
            height: 44px;
          }

          .btn-popover {
            padding: 7px 10px 2px 10px !important;
            border: none;

            ion-icon {
              font-size: 28px;

              &.follow-icon {
                padding-right: 5px;
              }
            }
          }

          &.first {
            padding-right: 0;
            margin-right: 8px;
          }

          &.second {
            padding-right: 0;
          }

          &.third {
            .btn-update {
              background-color: transparent !important;
              color: var(--ion-color-primary);
              border: none !important;
              min-width: max-content;
              padding-left: 0;
              font-size: 14px;
            }
          }

          &:hover {
            color: var(--ion-text-color);
            border-bottom: 2px solid;

            &.first {
              border-bottom: none !important;
            }

            &.second {
              border-bottom: none !important;
            }

            &.third {
              border-bottom: none !important;
            }
          }

          &.active {
            color: var(--ion-text-color);
            cursor: default;

            font: {
              family: $fontMedium;
            }
          }
        }

        &.btns {
          &.plots {
            .btn-update {
              line-height: 20px;
              padding: 8px 12px;

              ion-icon {
                font-size: 20px;
                padding: 0;
                margin-right: 4px;
              }

              @media (max-width: 480px) {
                padding: 4px 4px;

                font-size: 12px;

                ion-icon {
                  font-size: 16px;
                  margin-right: 2px;
                }
              }
            }

            @media (max-width: 480px) {
              justify-content: left;
            }
          }
        }
      }

      @media (max-width: 1200px) {
        ion-col {
          &.order {
            order: 2;
          }
        }
      }

      @media (max-width: 992px) {
        ion-col {
          &.btns {
            padding-left: 0;
            justify-content: flex-end;
          }

          .item {
            font-size: 16px;
          }
        }
      }

      @media (max-width: 768px) {
        ion-col {
          &.btns {
            padding-right: 16px;
          }
        }
      }
    }
  }

  &.detail-content {
    max-width: 1248px;
    padding-bottom: 10px;
    padding: 32px 24px 10px 24px;

    &.news-item {
      padding: 12px 24px 10px 24px;
    }

    a {
      &.link {
        font-family: $fontMedium;
        font-size: 16px;
        line-height: 18px;
        text-decoration: none;
        border-bottom: 1px solid var(--ion-color-primary);
      }
    }

    ion-row {
      &.main-row {
        // max-width: 1098px;
        // margin: 0 auto 40px auto;

        ion-col {
          &.calendar-title {
            padding: 28px 0 10px 0;

            @media (max-width: 1032px) {
              padding-left: 16px !important;
              padding-right: 16px !important;
            }



            h4 {
              margin: 0;
            }
          }

          &.calendar-title-top {
            justify-content: space-between;
            padding: 28px 65px 10px 0;

            @media (max-width: 1032px) {
              padding-left: 16px !important;
              padding-right: 16px !important;
            }

            p {
              margin: 0;
              font-size: 14px;
            }

            h4 {
              margin: 0;
            }
          }

          &.content-segment {
            padding: 0 32px 0 0;

            &.news-item {
              border-right: 1px solid var(--ion-color-medium-shade);
            }

            @media (max-width: 992px) {
              padding: 0;

              &.news-item {
                border-right: unset;
              }
            }

            &.order {
              padding: 0 28px 0 0;

              @media (max-width: 992px) {
                order: 2;
              }
            }

            .main {
              h4 {
                margin-top: 24px;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 6px;

                &.ion-no-margin {
                  margin: 0;
                }
              }

              .content {
                font-family: $fontRegular;
                line-height: 22px;
                padding-top: 0;
                font-size: 16px !important;

                p {
                  margin-top: 0;
                }
              }

              .overview {
                ion-row {
                  &.title {
                    padding: 48px 0 12px 0;

                    ion-col {
                      padding: 0;

                      h4 {
                        margin: 0 0 10px 0;
                      }

                      p {
                        margin: 0;
                        font-size: 14px;

                        span {
                          padding: 0
                        }
                      }
                    }

                    &.top {
                      padding: 0 0 8px 0;
                    }
                  }

                  &.groups {
                    gap: 16px;
                    grid-auto-columns: 1fr;
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;



                    ion-card {
                      margin: 0;

                      img {
                        height: 124px;
                        width: 100%;
                        object-fit: cover;
                      }

                      ion-card-title {
                        font: {
                          family: $fontBold;
                          size: 18px;
                        }

                      ;
                      }

                      ion-card-subtitle {
                        font: {
                          family: $fontBold;
                          size: 16px;
                        }

                      ;
                      }

                      ion-card-content {
                        font: {
                          family: $fontRegular;
                          size: 14px;
                        }

                      ;

                        color: var(--ion-text-color);
                      }
                    }

                    @media (max-width: 768px) {
                      grid-template-columns: 1fr 1fr;
                    }

                    @media (max-width: 576px) {
                      grid-template-columns: 1fr;

                      ion-card {
                        ion-card-header {
                          padding: 8px;
                        }

                        ion-card-content {
                          padding: 0 8px 12px 8px;
                        }
                      }
                    }
                  }
                }
              }

              .members {
                ion-row {
                  &.title {
                    ion-col {
                      padding: 0;

                      p {
                        &.popover {
                          color: var(--ion-color-medium);
                          font-size: 14px;
                          margin: 0;
                          cursor: pointer;

                          span {
                            color: var(--ion-text-color);
                          }

                          ion-icon {
                            vertical-align: sub;
                            font-size: 16px;
                            color: var(--ion-text-color)
                          }
                        }
                      }
                    }
                  }
                }

                ion-list {
                  background: transparent;

                  &.list-initiatives {
                    padding-bottom: 32px;

                    ion-item {
                      --padding-start: 0px;
                      --background: transparent;

                      ion-avatar {
                        width: 40px;
                        height: 40px;
                      }

                      h4 {
                        font-size: 14px;
                        line-height: 18px;
                      }

                      p {
                        font-size: 14px;
                        text-decoration: underline;
                        color: var(--ion-color-primary);
                        cursor: pointer;
                        margin: 0;
                        width: fit-content;

                        &.date {
                          color: var(--ion-color-medium);
                          text-decoration: none;
                          margin: 0;
                          cursor: unset;
                        }
                      }
                    }

                    &.members {
                      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
                      border-radius: 6px !important;
                      background: var(--ion-color-light);
                      padding: 24px 40px;
                      margin-top: 12px;

                      @media (max-width: 768px) {
                        padding: 24px;
                      }

                      @media (max-width: 480px) {
                        padding: 12px;
                      }
                    }

                    .members-follower-lots {
                      margin-top: 8px;

                      p {
                        &.melding {
                          font-size: 14px;
                          color: var(--ion-color-medium);
                          margin: 0;
                          min-width: max-content;
                        }
                      }

                      .list {
                        display: flex;
                        -webkit-box-pack: start;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        .item {
                          margin-right: 0.5em;
                          margin-bottom: 0.5em;
                          border: 1px solid rgba(0, 0, 0, .05);
                          border-radius: 4px;
                          background-color: var(--ion-color-tertiary);
                          padding: 8px;
                          text-align: center;
                          line-height: 12px;

                          font: {
                            size: 14px;
                            family: $fontSemiBold;
                          }

                          color: var(--color);
                          text-decoration: initial;

                          &.popover {
                            cursor: pointer;
                            padding: 4px 8px 8px 8px;

                            button {
                              background: transparent;
                              color: var(--ion-text-color);
                              padding: 0 !important;
                              line-height: 16px;

                              font: {
                                size: 14px;
                                family: $fontBold;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .info {

                .info-block {
                  p {
                    margin: 8px 0 24px 0;
                  }
                }

                img {
                  height: 380px;
                  width: 100%;
                  object-fit: cover;
                  margin-top: 20px;

                  &.design {
                    height: 325px;
                  }

                  @media (max-width: 480px) {
                    height: 240px;

                    &.design {
                      height: 240px;
                    }
                  }
                }

                p {
                  &.undertitle {
                    margin: 6px 0 0 0;
                    color: var(--ion-color-medium);

                    font: {
                      family: $fontMedium;
                      size: 15px;
                    }

                  ;
                  }
                }
              }

              .side-menu-info {
                position: sticky;
                position: -webkit-sticky;
                top: 100px;
                margin-left: -160px;
                align-self: flex-start;

                .side-menu-info-width {
                  width: 160px;
                }

                .item {
                  color: var(--ion-color-medium);

                  font: {
                    family: $fontMedium;
                    size: 15px;
                  }

                  line-height: 24px;
                  cursor: pointer;

                  &.active {
                    color: var(--ion-text-color);
                  }
                }

                @media (max-width: 1570px) {
                  display: none !important;
                  position: sticky;
                  top: 57px;
                  padding: 10px 0 10px 0;
                  margin-left: 0;
                  margin-bottom: 20px;
                  display: flex;
                  background: var(--ion-background-color);

                  .item {
                    padding: 0 5px;
                  }
                }
              }
            }

            @media (max-width: 1032px) {
              &.order {
                padding: 0 16px;
              }
            }
          }

          &.sidebar {
            padding: 0 0 0 0;
            height: fit-content;
            position: sticky !important;
            top: 116px !important;

            @media (max-width: 1032px) {
              padding: 0 0 0 0;
            }

            &.calendar-overview {
              margin-top: 0;
              position: sticky !important;
              top: 105px !important;
            }

            @media (max-width: 767px) {
              &.calendar-overview {
                margin-bottom: 16px;
                padding: 0 16px;

                .main {
                  padding: 16px;

                  .list-side {
                    display: flex;
                    justify-content: space-between;

                    .item {
                      padding: 0;
                    }

                    &.detail {
                      display: block;

                      .item {
                        padding-bottom: 8px;
                      }
                    }
                  }
                }
              }
            }

            .main {
              border-radius: 12px;
              padding: 0;

              &.default {
                padding: 0 0 8px 0;
              }

              .location {
                padding-bottom: 32px;

                h4 {
                  margin: 0;
                  padding-bottom: 5px;
                }

                p {
                  font-size: 14px;
                  color: var(--ion-color-medium);
                  margin: 0;
                }

                .map {
                  height: 300px;
                  width: 100%;

                  @media (max-width: 767px) {
                    height: 360px;
                  }
                }
              }

              h4 {
                margin: 0;
                font-size: 20px;
                line-height: 24px;
              }

              ion-list {
                background: transparent;

                &.list-initiatives {
                  padding-bottom: 32px;

                  &.members {
                    display: grid;
                    grid-template-columns: repeat(5, minmax(0, max-content));
                    gap: 12px;

                    ion-item {
                      --inner-padding-end: 0;
                      --min-height: 32px;

                      ion-avatar {
                        margin: 0;
                        margin-inline-end: 0;
                      }

                      &.more {
                        ion-label {
                          font: {
                            size: 14px;
                            family: $fontSemiBold;
                          };

                          color: var(--ion-color-primary);
                          padding-left: 8px;
                        }
                      }
                    }

                    @media only screen and (max-width: 320px) {
                      grid-template-columns: repeat(3, minmax(0, max-content));
                    }
                  }

                  ion-item {
                    --padding-start: 0px;

                    h4 {
                      font-size: 18px;

                      font: {
                        family: $fontSemiBold;
                      }

                    ;
                    }

                    p {
                      font-size: 14px;
                      text-decoration: underline;
                      color: var(--ion-color-gray);
                      cursor: pointer;
                      width: fit-content;
                      z-index: 2;

                      &:hover {
                        color: var(--ion-color-primary);
                      }
                    }

                    &.member-top {
                      ion-label {
                        h4 {
                          font-size: 14px;
                          line-height: 18px;
                        }

                        p {
                          font-family: $fontSemiBold;
                          font-size: 12px;
                          text-decoration: none;

                          &:hover {
                            color: var(--ion-color-gray);
                          }
                        }
                      }

                      ion-avatar {
                        width: 40px;
                        height: 40px;
                        margin-inline-end: 11px;
                      }
                    }
                  }
                }

                ion-item {
                  --background: transparent;
                }
              }

              ion-grid {
                &.members {
                  padding: 0px;

                  ion-row {
                    padding: 0;

                    ion-col {
                      padding-bottom: 16px;

                      h4 {
                        margin: 0;
                      }

                      a {
                        font-size: 12px;
                      }

                      ion-avatar {
                        width: 54px;
                        height: 54px;
                      }
                    }

                    &.head {
                      ion-col {
                        padding-bottom: 16px;
                      }
                    }
                  }
                }
              }

              &.second {
                margin-top: 32px;

                .documents {
                  padding-top: 32px;

                  h4 {
                    margin: 0;
                    padding-bottom: 5px;
                  }

                  ion-list {
                    &.documents {
                      padding-top: 0;
                      background: transparent;
                      padding-bottom: 0;

                      ion-item {
                        --padding-start: 0;
                        --background: transparent;
                        cursor: pointer;
                        --min-height: 32px;


                        ion-label {
                          font: {
                            family: $fontRegular;
                            size: 16px;
                          }

                          margin-left: 16px;

                        }

                        &:last-child {
                          --border-width: 0 0 0 0;
                        }
                      }

                    }
                  }
                }



                .documents {
                  padding-top: 0;
                }
              }

              @media (max-width: 992px) {
                padding: 24px 0px;
              }
            }

            @media (max-width: 992px) {
              padding: 0;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}

.header-container {
  max-width: 1248px;
  margin: 0 auto;
  padding: 5px 24px;
}

.members-sidebar {
  .default {
    cursor: unset !important;
  }

  .members {
    display: none;
  }
}

.overview {
  .kavel {
    padding-top: 32px;

    &.detail {
      padding-top: 0;

      h4 {
        padding-bottom: 0;
      }
    }

    h4 {
      margin: 0;
      padding-bottom: 5px;
    }

    ion-card {
      box-shadow: 0px 0px 14px rgb(0 0 0 / 5%);
      margin: 12px 0 0 0;
      width: 100%;
      border-radius: 10px;

      img {
        height: 177px;
        width: 100%;
        object-fit: cover;
      }

      ion-card-title {
        font: {
          family: $fontBold;
          size: 18px;
        }

      ;
      }

      ion-card-subtitle {
        font: {
          family: $fontBold;
          size: 16px;
        }

      ;
      }

      ion-card-content {
        font: {
          family: $fontRegular;
          size: 14px;
        }

        color: var(--ion-text-color);
      }
    }
  }

  .location {

    h4 {
      margin: 0;
      padding-bottom: 5px;
    }

    p {
      font-size: 14px;
      color: var(--ion-color-medium);
      margin: 0 0 20px 0;
    }

    .map {
      height: 370px;
      width: 100%;
    }
  }

  .documents {
    margin-top: 48px;

    h4 {
      margin: 0;
      padding-bottom: 5px;
    }
  }
}

.show-post {
  ion-row {
    &.title {
      padding: 0 0 8px 0;

      ion-col {
        padding: 0;
      }

      P {
        &.link {
          margin: 0;
        }
      }
    }
  }
}

.members {
  ion-searchbar {
    padding: 0;
  }
}

.sticky-div {
  top: 4px;
  z-index: 9998;
  position: sticky;
  background: var(--ion-background-color);

  ion-col {
    &.order {
      overflow-x: auto;
    }
  }

  .continue {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    background-image: linear-gradient(to right, rgba(244, 242, 238, 0.85), rgba(244, 242, 238, 1));
    height: 100%;
    width: 58px;
    cursor: pointer;
    z-index: 10001;

    ion-icon {
      font-size: 24px;
      margin-bottom: 10px;
      margin-right: -20px;
      color: rgba(0, 0, 0, 0.35);
    }

    &:hover {
      ion-icon {
        color: var(--ion-color-primary);
      }
    }

    @media (max-width: 768px) {
      display: flex;
    }
  }
}

.news-grid {
  row-gap: 24px;
  // grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;

  // @media (max-width: 992px) {
  //   grid-template-columns: 1fr 1fr 1fr;
  // }

  // @media (max-width: 640px) {
  //   grid-template-columns: 1fr 1fr;
  // }

  // @media (max-width: 480px) {
  //   grid-template-columns: 1fr;
  // }
}

ion-grid {
  &.header-group-detail {
    .indication-container {
      padding-top: 8px;
      display: flex;
      align-items: center;
      gap: 16px;

      p {
        margin: 0;
        color: var(--ion-color-medium);
        font-size: 14px;
      }

      ion-icon {
        font: {
          size: 18px;
        };

        vertical-align: sub;
      }
    }
  }
}

.sidebar-content-news-item {
  padding: 0 40px;

  .melding {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    a {
      &.add {
        text-decoration: none;
        color: var(--ion-color-medium);
        font-size: 14px;

        ion-icon {
          font-size: 18px;
          vertical-align: sub;
        }

        &:hover {
          color: var(--ion-color-primary);
        }
      }
    }
  }
  h4 {
    margin-top: 0;
    margin-bottom: 4px;

    &.red {
      font-family: $fontMedium;
      color: var(--ion-color-primary);
    }
  }

  p {
    color: var(--ion-color-medium);
    margin: 0;
    font-size: 16px;
  }

  ion-item {
    --padding-start: 0;
    margin-bottom: 32px;

    ion-label {
      font: {
        family: $fontMedium !important;
        size: 14px !important;
      };

      p {
        &.date {
          font: {
            family: $fontMedium;
            size: 12px;
          }

          color: var(--ion-color-medium);
        }
      }
    }
  }

  @media (max-width: 992px) {
    padding: 24px 0;
  }
}

.items-wrapper {
  display: flex;
  gap: 12px;

  &-inner {
    display: flex;;
    gap: 12px;
  
    & .item {
      border-bottom: none!important;
      margin: 0!important;
    }
  }
}
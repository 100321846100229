ion-header {
  ion-toolbar {
    text-align: center;
    --background: var(--ion-background-color);

    ion-row {
      padding: 0 15px;
    }

    ion-icon {
      font-size: 33px;
      color: var(--ion-text-color);
      cursor: pointer;
    }

    .logo {
      height: auto;
      max-height: 56px;
    }

    .avatar-signup {
      text-align: center;
      text-decoration: none;
      background: none;
      color: var(--ion-text-color);

      font: {
        family: $fontMedium;
        size: 0.75rem;
      }

      ion-icon {
        font-size: 1.5rem;
        color: var(--ion-color-primary);
      }
    }

    .avatar-loggedin {
      cursor: pointer;
      ion-avatar {
        width: 34px;
        height: 34px;
      }
    }

    h5 {
      margin: 0;
      font-size: 17px;
      color: var(--ion-text-color);
      padding-left: 12px;
    }
  }

  .close {
    cursor: pointer;
  }

  &.modal-password {
    ion-toolbar {
      text-align: center;

      ion-row {
        padding: 0 24px;

        &:first-child {
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid var(--ion-color-light-shade);
        }


      }

      ion-icon {
        font-size: 32px;
        color: var(--ion-text-color);
      }
    }
  }
}

.group {
  ion-grid {
    &.desktop {
      position: absolute;
      background: transparent;
      top: 0;
      right: 0;
      left: 0;
      z-index: 333;

      img {
        display: none;
      }
    }
  }
}

.messages {
  ion-grid {
    &.desktop {
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
  }

  ion-header {
    &.ion-hide-md-up {
      @media only screen and (max-width: 992px) {
        display: block !important;
      }
    }
  }
}

ion-grid {
  &.desktop {
    background: var(--ion-background-color);
    padding: 12px 0;
    ion-col {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
    @media only screen and (max-width: 1232px) {
      padding: 12px 24px;
    }
    @media only screen and (max-width: 768px) {
      .btn-login {
        padding: 4px 6px;
        font-size: 12px;
      }

      .btn-update {
        padding: 4px 6px;
        font-size: 12px;
      }

      .main {
        font-size: 14px !important;
        padding: 10px 4px 10px 4px !important;
      }
    }

    .logo {
      height: auto;
      max-height: 56px;
    }

    .main {
      cursor: pointer;
      padding: 0;
      border-radius: 4px;
      background-color: transparent;
      color: var(--ion-color-primary);
      line-height: 1em;
      position: relative;
      margin: 0;

      font: {
        family: $fontMedium;
        size: 1rem;
      };

      .menu-link {
        display: inline-block;
        text-decoration: none;
        padding: 8px 16px;

        &.active {
          text-decoration: underline;
        }
      }

      .sub-menu {
        padding: 10px;
        border-radius: 4px;
        z-index: 999999;
        display: none;
        width: 248px;
        position: absolute;
        background: var(--ion-color-light);
        min-width: 100%;
        top: 38px;
        left: 0;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 4%), 0 1px 3px 0 rgb(0 0 0 / 10%);
        font: {
          family: $fontMedium;
          size: 1rem;
        }

        .sub-menu-item {
          --background-activated: var(--ion-color-dark);
          --background-activated-opacity: 0.1;
          --background-focused: var(--ion-color-dark);
          --background-focused-opacity: 0.1;
          --background-hover: var(--ion-color-dark);
          --background-hover-opacity : 0.1;
          --ripple-color: var(--ion-color-dark);
          --color: var(--ion-color-primary);
          --color-hover: var(--ion-color-primary);
          --color-focused: var(--ion-color-dark);
          --border-radius: 4px;

          font: {
            family: $fontMedium;
            size: 1rem;
          }

          &.active {
            text-decoration: underline;
          }

          &::part(native) {
            background: var(--ion-color-light);
          }
        }
      }

      &:hover {
        background-color: rgba(0,0,0,.1);
        .sub-menu {
          display: block;
        }
      }

      ion-icon {
        font-size: 22px;
        padding-left: 2px;
        vertical-align: text-top;
      }
    }

    .cursor {
      cursor: pointer;
      padding: 7px;
      border-radius: 4px;
      &:hover {
        background-color: rgba(0,0,0,.1);
      }
    }

    ion-icon {
      &.up {
        color: black;
        font-size: 20px;
        vertical-align: sub;
      }

      &.unread-notifications-icon {
        font-size: 24px;
      }
    }

    ion-badge {
      position: absolute;
      top: 2px;
      right: -3px;
      font: {
        family: $fontSemiBold;
      }
    }

    .label {
      font: {
        family: $fontBold;
        size: 14px;
      }

      color: var(--ion-color-primary);
    }

    ion-avatar {
      width: 22px;
      height: 22px;
    }

    .name {
      font: {
        family: $fontBold;
        size: 16px;
      }

      padding: 0 8px;
    }
  }
}

.popover-header {
  --offset-y: 10px;
  .link {
    font: {
      family: $fontMedium;
      size: 16px;
    }

    line-height: 1.5em;
    padding: 12px 10px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: rgba(0,0,0,.1);
    }

    &.active {
      color: var(--ion-color-primary);
    }
  }

  &.link {
    .ion-item-link {

      a {
        font: {
          family: $fontMedium;
          size: 16px;
        }

        line-height: 1.5em;
        cursor: pointer;
        border-radius: 4px;
        color: var(--ion-color-dark);
        text-decoration: none;

        &:hover {
          color: var(--ion-color-primary);
        }

        &.active {
          color: var(--ion-color-primary);
        }
      }

      &:last-child {
        line:none;
      }
    }

    ion-content {
      --background: unset !important;
    }
  }
}

.popover-report {
  --box-shadow: none;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0);
  --background: transparent;
  --min-width: 248px;
  .popover-viewport {
    .report-container {
      font: {
        family: $fontMedium;
        size: 18px;
      }

      color: var(--ion-color-medium);
      position: absolute;
      top: 30px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      cursor: pointer;
    }

    img {
      width: 100%;
      padding-top: 3px;
      position: relative;
      cursor: pointer;
    }
  }
}
ion-popover {
  &.popover-rapport-like {
    margin-top: 5px;
    --box-shadow: none;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0);
    --background: transparent;
    --min-width: 248px;
    ion-content {
      --background: var(--ion-background-color);
      ion-list {
        border: 2px solid var(--ion-color-light-shade);
        --background: var(--ion-background-color);
        border-radius: 6px;
        margin-top: 10px;
        margin: 0;
        padding: 0;
        ion-item {
          cursor: pointer;
          --min-height: 32px;
          --padding-end: 16px;
          ion-label {

            font: {
              family: $fontMedium;
              size: 14px;
            }

            color: var(--ion-color-medium);
          }

          &:last-child {
            --border-color: transparent;
          }
        }
      }
    }
    .popover-viewport {
      div {
        background: var(--ion-background-color);
        border: 2px solid var(--ion-color-light-shade);
        border-radius: 6px;
        margin-top: 10px;
        ion-list {
          margin: 0 5px;
          padding: 0;
          ion-item {
            cursor: pointer;
            --min-height: 32px;
            --padding-end: 16px;
            ion-label {

              font: {
                family: $fontMedium;
                size: 14px;
              }

              color: var(--ion-color-medium);
            }

            &:last-child {
              --border-color: transparent;
            }
          }
        }
      }
    }
  }
}

ion-segment {
  &.languages {
    border-radius: 4px;
    width: 80px;

    ion-segment-button {
      min-height: 37px;
      min-width: 40px;
      --border-radius: 4px;
      font: {
        family: $fontRegular;
        size: $font16;
      };

      --indicator-color: none;
      --background-hover: none;
      --color: var(--ion-color-medium-tint);
      --color-hover: var(--ion-color-primary);
      --color-checked: var(--ion-color-primary);
    }

    .slash {
      color: var(--ion-color-primary);
    }
  }

  &.segment-initiator {
    justify-content: flex-start;
    gap: 24px;
    flex-wrap: wrap;

    ion-segment-button {
      cursor: pointer;
      background: transparent;
      min-height: 30px;
      padding-start: 0;
      inner-padding-end: 0;
      min-width: unset;
      max-width: 360px;
      flex: unset;
      --padding-start: 0;
      --padding-end: 0;
      --background-hover: unset;
      --color: transparent;
      --color-checked: transparent;
      --indicator-box-shadow: transparent;
      --indicator-transition: unset;
      --indicator-transform: unset;
      --indicator-color: unset;

      ion-label {
        display: flex;
        align-items: center;
        color: var(--ion-color-gray);
        text-transform: none;
        font: {
          family: $fontBold !important;
          size: 16px;
        }

        line-height: 24px;
        margin: 0;
      }

      ion-badge {
        margin-left: 12px;
      }

      &:hover {
        background: transparent;
        ion-label {
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &.segment-button-checked {
        ion-label {
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }

    @media (max-width: 480px) {
      gap: 8px;
      display: block;

      ion-segment-button {
        display: table;
        margin-bottom: 5px;
      }
    }
  }
}

.request-group-member-modal {
  --border-radius: 20px;
  padding: 16px;
  .request-group-modal {
    --box-shadow: none;

    ion-toolbar {
      --background: var(--ion-color-light);
    }

    ion-title {
      font: {
        family: $fontSemiBold;
        size: 18px;
      }
    }

    ion-progress-bar {
      height: 8px;
    }

    &:after {
      background-image: none;
    }
  }

  .buttons {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    @media (max-width: 480px) {
      left: 16px;
      right: 16px;
    }
    .btn-voor {
      padding: 6px 12px;
      border-radius: 4px;
      background-color: var(--ion-color-dark);
      color: var(--ion-color-light);
      text-align: center;
      line-height: 20px;
      min-width: 100px;

      font: {
        family: $fontBold;
        size: $font16;
      };

      &:hover {
        background-color: var(--ion-color-black);
      }
    }

    .btn-next {
      background: var(--ion-color-primary);
      &:hover {
        background-color: var(--ion-color-primary-shade);
        color: var(--ion-color-light);
      }
    }
  }
}

.join-request-form {
  padding: 24px;

  .join-request-input-container {
    text-align: left;

    h3 {
      margin-top: 0;
      font: {
        family: $fontSemiBold;
        size: 20px;
      };
    }

    .content {
      margin-top: 0;
      line-height: 17px;
      margin-bottom: 32px;
      font: {
        family: $fontMedium;
        size: 14px;
      };
    }
  }
}

.news-detail-modal {
  --border-radius: 10px;

  .news-detail-content {
    padding: 24px;

    img {
      height: 310px;
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 480px) {
      padding: 14px 16px 24px 16px;
    }

    p {
      margin: 8px 0;

      &.date-calendar {
        color: var(--ion-color-medium);
        font: {
          family: $fontRegular;
          size: 14px;
        }
      }
    }

    .article-title {
      margin: 8px 0;
    }

    .top {
      justify-content: space-between;
      margin-bottom: 12px;

      &.calendar {
        justify-content: end;
      }

      .close-btn {
        font-size: 24px;
        color: var(--ion-text-color);
        margin-right: -4px;
        cursor: pointer;

        &:hover {
          color: var(--ion-color-primary);
        }
      }

      p {
        margin: 0;
        color: var(--ion-color-medium);
        font: {
          family: $fontRegular;
          size: 16px;
        }
      }

      .date-calendar {
        color: var(--ion-color-secondary);
        font: {
          family: $fontRegular;
          size: 16px;
        }

        p {
          color: var(--ion-color-medium);
          margin-top: 4px;
          font: {
            family: $fontRegular;
            size: 14px;
          }
        }
      }
    }

    h1 {
      color: var(--ion-text-color);
    }

    .content {
      font-size: 16px;
      color: var(--ion-color-dark);
    }

    .footer {
      flex-flow: wrap;
      justify-content: space-between;
      margin-top: 40px;

      .document {
        color: var(--ion-color-medium);

        font: {
          family: $fontRegular;
          size: 16px;
        };

        ion-icon {
          font-size: 20px;
          vertical-align: bottom;
          padding-right: 5px;
          margin-inline: unset;
        }

        &.share {
          color: var(--ion-color-primary);
        }
      }

      .followers {
        padding-right: 10px;

        .date {
          font-size: 12px;
          color: var(--ion-color-medium);
        }

        .images {
          display: flex;
          flex-wrap: nowrap;

          img {
            position: relative;
            left: auto;
            height: 34px;
            width: 34px;
            margin-left: -10px;
            border: 2px solid var(--ion-color-light);
            border-radius: 50%;

            &:first-child {
              margin-left: 0;
            }
          }

          ion-badge {
            position: relative;
            left: auto;
            width: 48px;
            margin-left: -16px;
            border: 2px solid var(--ion-color-light);
            border-radius: 50%;
            display: flex;
            font-size: 18px;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .content-padding {
    padding: 24px;
  }
}

.default-modal {
  .content {
    padding: 24px;
  }
}

.register-modal {
  @media (min-width: 960px) {
    --min-height: 720px;
    --border-radius: 10px;
  }
}

.ion-full-modal {
  @media (min-width: 960px) {
    --height: 100%;
    --width: 100%;
    --border-radius: 10px;

    padding: 40px;
  }
}

.ion-large-modal {
  --height: 100%;
  --width: 80%;
  @media (max-width: 992px) {
    --height: 80%;
  }

  @media (max-width: 576px) {
    --height: 50%;
    --width: 100%;
    padding: 0 24px;
  }

  ion-content {
    &.image-slider {
      --offset-top: -85px !important;
      --overflow: unset !important;

      .image-slider-container {
        max-width: 1200px;
        margin: 0 auto;

        ion-button {
          font-size: 24px;
          color: var(--ion-color-primary);

          &:hover {
            opacity: 0.7;
          }
        }

        @media (max-width: 576px) {
          ion-button {
            font-size: 18px;
          }
        }
        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background: var(--ion-color-light);
            opacity: 0.5;

            &.swiper-pagination-bullet-active {
              background: var(--ion-color-primary);
              opacity: 0.9;
            }

            @media (max-width: 576px) {
              width: 6px;
              height: 6px;
            }
          }
        }
        .swiper-button-next {
            &:after {
              color: var(--ion-color-primary);
              font-size: 24px;
              font-weight: bold;
              padding-left: 2px;
              @media (max-width: 576px) {
                font-size: 18px;
              }
            }
        
            &:hover {
              opacity: 0.7;
            }
        
          &.swiper-button-disabled {
            display: none;
          }
        }
        
        .swiper-button-prev {
            &:after {
              color: var(--ion-color-primary);
              font-size: 24px;
              font-weight: bold;
              padding-right: 2px;
              @media (max-width: 576px) {
                font-size: 18px;
              }
            }
        
            &:hover {
              opacity: 0.7;
            }
        
          &.swiper-button-disabled {
            display: none;
          }
        }
      }
    }
  }
}

ion-button {
  &.close-modal {
    font-size: 24px;
    color: var(--ion-color-primary);
  
    &:hover {
      opacity: 0.7;
    }
  }
}



// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ea505a;
  --ion-color-primary-rgb: 234,80,90;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #ce464f;
  --ion-color-primary-tint: #ec626b;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #f0eade;
  --ion-color-tertiary-rgb: 240,234,222;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #d3cec3;
  --ion-color-tertiary-tint: #f2ece1;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #757575;
  --ion-color-medium-rgb: 117,117,117;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #676767;
  --ion-color-medium-tint: #838383;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-color-light-gray: #EEEEEE;
  --ion-color-light-gray-rgb: 238,238,238;
  --ion-color-light-gray-contrast: #000000;
  --ion-color-light-gray-contrast-rgb: 0,0,0;
  --ion-color-light-gray-shade: #d1d1d1;
  --ion-color-light-gray-tint: #f0f0f0;

  /** black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0,0,0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255,255,255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** gray **/
  --ion-color-gray: #999999;
  --ion-color-gray-rgb: 153,153,153;
  --ion-color-gray-contrast: #000000;
  --ion-color-gray-contrast-rgb: 0,0,0;
  --ion-color-gray-shade: #878787;
  --ion-color-gray-tint: #a3a3a3;

  --ion-font-family: 'Inter-Regular';
  --ion-font-family-regular: 'Inter-Regular';
  --ion-font-family-medium: 'Inter-Medium';
  --ion-font-family-bold: 'Inter-Bold';
  --ion-font-family-semi-bold: 'Inter-SemiBold';

  --ion-background-color: #F4F2EE;
  --ion-background-color-rgb: 244,242,238;
  --ion-background-color-contrast: #000000;
  --ion-background-color-contrast-rgb: 0,0,0;
  --ion-background-color-shade: #d7d5d1;
  --ion-background-color-tint: #f5f3f0;

  --ion-item-background: #FFFFFF;
  --ion-text-color: #000000CC;
  --ion-text-color-rgb: 0,0,0;

  --ion-input-border-color: var(--ion-color-light-shade);
  --ion-input-hover-border-color: var(--ion-color-black);
  --ion-input-focus-border-color: var(--ion-color-black);
}

.ion-color-light-gray {
  --ion-color-base: var(--ion-color-light-gray);
  --ion-color-base-rgb: var(--ion-color-light-gray-rgb);
  --ion-color-contrast: var(--ion-color-light-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-gray-shade);
  --ion-color-tint: var(--ion-color-light-gray-tint);
}

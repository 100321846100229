//ng-select
.ng-select {
  .ng-select-container {
    border: 2px solid var(--ion-input-border-color);
    border-radius: 4px;
    caret-color: var(--ion-color-black);
    font-family: var(--ion-font-family-medium);
    color: var(--ion-text-color);
    font-size: 16px;
    min-height: 42px!important;
  }

  .ng-select-container:hover {
    cursor: pointer;
    border-color: var(--ion-input-hover-border-color);
    box-shadow: none;
  }

  .ng-select-container .ng-value-container .ng-input {
    top: 8px!important;
  }

  .ng-select-container .ng-value-container .ng-input>input:hover {
    cursor: pointer;
  }

  .ng-select-container span.ng-arrow-wrapper {
    width: 21px;
    height: 21px;
    padding-right: 34px;

    span.ng-arrow {
      top: 0;
      width: 21px;
      height: 21px;
      background-color: var(--ion-color-black);
      mask-image: url(/assets/custom-ion-icons/custom-chevron-down.svg);
      mask-size: contain;
      -webkit-mask-image: url(/assets/custom-ion-icons/custom-chevron-down.svg);
      -webkit-mask-size: contain;

      border: none;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 8px;
    font-family: var(--ion-font-family-regular);
    font-size: 16px;
    color: var(--ion-text-color);
  }

  &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background: var(--ion-color-tertiary);
  }

  &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: var(--ion-color-tertiary-shade);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: rgba(var(--ion-text-color-rgb), 0.1);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: rgba(var(--ion-text-color-rgb), 0.05);
  }

  &.ng-select-opened>.ng-select-container {
    border-color: var(--ion-input-focus-border-color);
  }

  &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: var(--ion-input-focus-border-color);
  }

  &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid var(--ion-color-tertiary);
  }

  &.register-form {
    .ng-select-container {
      margin-top: 8px;
    }
  }
}

.side-menu {
  --background: var(--ion-color-tertiary) !important;

  .logo {
    text-align: center;
    padding-top: 20px;
    img {
      max-width: 200px;
    }
  }

  .list {
    padding: 34px 16px;

    h3 {
      font: {
        family: $fontBold;
        size: 14px;
      }

      color: var(--ion-color-dark);
      margin: 40px 0 5px  0;
    }

    ion-list {
      padding: 0;
      margin: 0;
      background-color: var(--ion-color-tertiary);
      ion-item {
        --background: var(--ion-color-tertiary);
        cursor: pointer;
        --min-height: 40px;
        margin-bottom: 4px;

        &:hover {
          --background: rgba(0,0,0,.1);
          border-radius: 8px;
        }

        i,
        ion-icon {
          color: var(--ion-text-color);
          margin-inline-end: 16px;
          margin-top: 0;
          margin-bottom: 0;

          font: {
            size: 1.5rem;
            style: unset;
          }
        }

        &.active {
          --background: rgba(0,0,0,.1);
          border-radius: 8px;

          ion-label {
            font: {
              family: $fontBold;
            }
          }
        }

        ion-label {
          color: var(--ion-text-color);

          font: {
            family: $fontMedium;
            size: 16px;
          }
        }
      }
    }

    ion-accordion {
      padding: 0;
      margin: 0;
      background-color: var(--ion-color-tertiary);

      ion-item {
        --background: var(--ion-color-tertiary);
        cursor: pointer;
        --min-height: 40px;
        margin-bottom: 4px;
        --inner-padding-end: 0px;

        &:hover {
          --background: rgba(0,0,0,.1);
          border-radius: 8px;
        }

        ion-icon {
          font-size: 24px;
          color: var(--ion-text-color);
          margin-inline-end: 10px;
          margin-top: 0;
          margin-bottom: 0;
        }

        &.active {
          --background: rgba(0,0,0,.1);
          border-radius: 8px;

          ion-label {
            font: {
              family: $fontBold;
            }
          }
        }

        ion-label {
          color: var(--ion-text-color);

          font: {
            family: $fontMedium;
            size: 16px;
          }
        }

        &.header-menu-item {
          font: {
            family: $fontMedium;
          }

          a {
            color: var(--ion-text-color);
            text-decoration: none;

            &:hover {
              color: var(--ion-color-primary);
            }

            &.active {
              color: var(--ion-color-primary);
            }
          }
        }

        &.sub-menu-item {
          cursor: pointer;
          font: {
            family: $fontMedium;
          }

          --background: transparent;
          --padding-start: 32px;
          --inner-padding-end: 16px;
          --min-height: 32px;
  
          --color-hover: var(--ion-color-primary) !important;

          --background-hover-opacity : 0;
  
          
          ion-label {
            margin: 0;
            white-space: unset;
            font: {
              family: $fontMedium;
            }
            &:hover {
              color: var(--ion-color-primary);
            }
          }

          &.active {
            color: var(--ion-color-primary);
            font: {
              family: $fontMedium;
            }
            ion-label {
              color: var(--ion-color-primary);
            }
          }
        }
      }
    }
  }
}

.list-side {
  .item {
    font: {
      family: $fontSemiBold;
      size: 16px;
    }

    color: var(--ion-color-medium);
    padding-bottom: 8px;
    
    &:last-child {
      padding-bottom: 0;
    }

    &.active {
      color: var(--ion-text-color);
      font: {
        family: $fontSemiBold;
        size: 16px;
      }
    }

    &:hover {
      color: var(--ion-text-color);
    }
  }

  button {
    font: {
      family: $fontSemiBold;
      size: 16px;
    }

    color: var(--ion-color-medium);
    background-color: transparent;
    padding: 4px 6px;

    &.active {
      color: var(--ion-text-color);
    }

    &:hover {
      background-color: var(--ion-color-light-shade);
    }
  }

  &.detail {
    .item {
      ion-icon {
        font-size: 24px;
        vertical-align: sub;
        padding-right: 12px;
        color: var(--ion-color-medium);
      }
      &:hover {
        color: var(--ion-color-medium);

        &.active {
          color: var(--ion-text-color);
        }
      }
    }
  }
}

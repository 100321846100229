.ion-flex {
  display: flex;
  object-fit: cover;
}

.ion-flex-left {
  justify-content: flex-start;
}

.ion-flex-spacing {
  justify-content: space-between;
}

.ion-flex-center {
  justify-content: center;
}

.ion-flex-right {
  justify-content: flex-end;
}

.ion-flex-middle {
  align-items: center;
}

.ion-flex-bottom {
  align-items: flex-end;
}

.ion-flex-wrap {
  flex-wrap: wrap;
}

.ion-flex-column {
  flex-direction: column;
}

.ion-flex-row {
  flex-direction: row;
}

.ion-flex-row-reverse {
  flex-direction: row-reverse;
}

.ion-position-absolute {
  position: absolute;
}

.ion-display-block {
  display: block;
}

.ion-position-relative {
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.ion-position-fixed {
  position: fixed;
}

.ion-full-width {
  width: 100%;
}

.ion-margin-left-auto {
  margin-left: auto;
}

.ion-full-width-mobile {
  @media (max-width: 480px) {
    width: 100% !important;
  }
}

.ion-full-height {
  height: 100%;
}

.ion-no-background {
  --background: transparent;
  --box-shadow: none;

  ion-content {
    --background: transparent !important;
  }
}

.ion-pointer {
  cursor: pointer;
}

.ion-gap {
  gap: 16px;
}

.ion-gap-small {
  gap: 8px;
}

//start 
.start {
  --background: var(--ion-background-color);

  ion-grid {
    max-width: 1440px;
    margin: 0 auto;

    ion-row {
      &.content {
        height: 90vh;
        justify-content: center;
      }

      ion-col {

        ion-img {
          max-width: 300px;
          margin: 0 auto;
        }

        p {
          font: {
            size: 18px;
          }

          color: var(--ion-color-dark);
          line-height: 1.4;
        }
      }
    }
  }

  .home-btn {
    a {
      color: inherit;
      text-decoration: none;
    }

    ion-icon {
      font-size: 32px;
      padding: 20px 20px 0 0;
    }

    &:hover {
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
  }
}

.profile-end {
  background: var(--ion-background-color);
  height: 100vh;
  padding: 0 16px;

  ion-row {
    &.main {
      max-width: 600px;
    }

    &.child {
      ion-col {
        padding: 12px;

        ion-card {
          border-radius: 8px;
          margin: 0;
          box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
          padding: 24px;
          cursor: pointer;
          transition: transform .3s, box-shadow .2s, -webkit-transform .3s;

          &:hover {
            transform: translate(0, -4px);
            box-shadow: 0 8px 20px 0 rgb(0 0 0 / 6%), 0 8px 16px 0 rgb(0 0 0 / 10%);
          }

          img {
            width: 150px;
            height: 120px;
          }

          ion-card-header {
            padding: 8px 0;
            line-height: 1.4;
            color: var(--ion-text-color);

            font: {
              family: $fontBold;
              font-size: 1.2em;
            }

            ;
          }

          ion-card-content {
            padding: 0;
            color: var(--ion-color-dark);
            line-height: 1.4em;

            font: {
              family: $fontRegular;
              font-size: 1em;
            }

            ;
          }
        }
      }
    }

    .btn-row {
      .btn {
        margin-top: 20px;
        padding: 6px 64px;
      }

      @media (max-width: 480px) {
        padding: 5px 12px;

        .btn {
          width: 100%;
        }

        ion-col {
          padding: 0;
        }
      }
    }

  }
}

.max-w-800 {
  max-width: 800px;
  margin: 0 auto;

  &.tijdline-main {
    max-width: unset;
    app-news-card {
      // .news-card  {
      //   display: flex;
      //   box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
      //   background: var(--ion-color-light);

      //   .card-body {
      //     background: transparent;
      //   }

      //   .img-div {
      //     flex: 0 0 280px;
      //     min-width: 280px;
      //     height: 200px;

      //     img {
      //       width: 100%;
      //       height: 100%;
      //       // border-radius: 10px 0 0 10px;
      //     }
      //   }

      //   @media (max-width: 576px) {
      //     flex-wrap: wrap;

      //     .img-div {
      //       flex: 0 0 100%;
      //       width: 100%;

      //       img {
      //         border-radius: 10px 10px 0 0;
      //       }
      //     }
      //   }
      // }
    }

    app-calendar-card {
      // .news-card  {
      //   display: flex;
      //   box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
      //   background: var(--ion-color-light);

      //   .card-body {
      //     background: transparent;
      //   }

      //   .img-div {
      //     flex: 0 0 280px;
      //     width: 280px;
      //     height: 200px;
      //     img {
      //       width: 100%;
      //       height: 100%;
      //       border-radius: 10px 0 0 10px;
      //     }
      //   }

      //   @media (max-width: 576px) {
      //     flex-wrap: wrap;

      //     .img-div {
      //       flex: 0 0 100%;
      //       width: 100%;

      //       img {
      //         border-radius: 10px 10px 0 0;
      //       }
      //     }
      //   }
      // }
    }
  }
}

.help-improve-platform {
  background: var(--ion-background-color);
  padding: 24px 12px 12px 12px;
}

.no-results {
  max-width: 600px;
  margin: 16px auto;
  text-align: center;
}

//discover
.discover {
  .main {
    max-width: 1248px;
    margin-right: auto;
    margin-left: auto;
    padding: 48px 8px 48px 8px;

    @media (max-width: 767px) {
      padding: 24px 8px;
    }

    .info {
      padding: 0 16px;

      .content {
        max-width: 500px;
        line-height: 1.4;
      }
    }

    ion-grid {
      &.segment-grid {
        ion-row {
          gap: 32px;
          padding: 0 16px;

          &.navigation {
            h2 {
              font-size: 26px !important;
            }

            @media (max-width: 1024px) {
              gap: 16px;
              h2 {
                font-size: 20px !important;
              }
            }

            @media (max-width: 768px) {
              h2 {
                font-size: 19px !important;
              }
            }

            @media (max-width: 640px) {
              gap: 12px;
              h2 {
                font-size: 18px !important;
              }
            }

            @media (max-width: 576px) {
              gap: 8px;

              a {
                display: inline-block;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid var(--ion-color-light-shade);

                &.active {
                  background: rgba(0, 0, 0, .1);
                }
              }
            }
          }
        }
      }

      &.segment {
        margin-top: 0;

        @media (max-width: 480px) {
          padding-top: 0;
          margin-top: 0;
        }

        ion-row {
          padding-top: 8px;
          padding-bottom: 8px;

          ion-col {
            ion-row {
              padding: 0;
            }

            &.map {
              ion-icon {
                font-size: 24px;
                cursor: pointer;
              }

              div {
                cursor: pointer;
              }
            }

            h2 {
              a {
                color: var(--ion-color-medium);
                text-decoration: none;

                &:hover {
                  color: var(--ion-color-dark);
                }

                &.active {
                  color: var(--ion-text-color);
                }
              }
            }
          }

          &.groups {
            ion-col {
              padding: 16px;

              @media (max-width: 480px) {
                padding: 0 16px 16px 16px;
              }

              ion-card {
                margin: 0;
              }
            }
          }
        }

        .filter {
          gap: 16px;

          padding-left: 16px;
          padding-right: 16px;

          ion-col {
            ion-button {
              width: 100%;
              margin: 0;
              min-height: 42px;
            }
          }
        }

        h2 {
          margin: 0;
        }

        h5 {
          margin: 0;
        }
      }
    }
  }
}

//allert
.select-options {
  --min-width: fit-content;

  @media (max-width: 480px) {
    --min-width: 320px;
  }

  .alert-button-group {
    &.sc-ion-alert-md {
      justify-content: space-between;
    }
  }
}


//modal
.edit-email-modal {
  --border-radius: 10px;
  padding: 0 10px;

  @media (max-width: 480px) {
    --height: 97%;
  }
}

.model-password {
  --background: #f3f3f3;

  ion-grid {
    display: flex;
    ion-row {
      flex-grow: 1;
      ion-col {
        &.first {
          background-color: var(--ion-background-color);
          box-shadow: 1px 0 5px 0 rgb(45 62 80 / 6%);
          padding: 40px 16px 24px 16px;

          .img {
            padding-bottom: 24px;
            border-bottom: 1px solid #F2F2F2;

            img {
              width: 140px;
              height: auto;
            }
          }

          .link {
            font: {
              family: $fontBold;
              size: 14px;
            }

            padding: 16px 0;
            color: var(--ion-color-dark);
            cursor: pointer;

            &.active {
              color: var(--ion-color-primary) !important;
            }

            &:hover {
              color: var(--ion-text-color);
            }

            &.logout {
              border-top: 1px solid #F2F2F2;
              padding-top: 24px;
            }
          }

          @media (max-width: 767px) {
            height: unset;
            padding-bottom: 0;
            border-bottom: 1px solid;
            border-color: rgba(45, 62, 80, 0.12);

            .img {
              img {
                width: 180px;
                height: auto;
              }
            }

            .links {
              position: relative;
              display: flex;

              .link {
                &:first-child {
                  margin-right: 20px;
                }

                &.logout {
                  border: none;
                  padding: 16px 0;
                  position: absolute;
                  top: 0;
                  right: 10px;
                }
              }
            }
          }
        }

        &.second {
          padding: 84px 24px;
          background-color: #f3f3f3;
          min-height: 100vh;

          @media (max-width: 480px) {
            padding: 48px 16px;
          }

          h4 {
            margin: 0;
          }
        }

        &.input {
          padding: 8px;
          box-shadow: 0 1px 8px 0 rgb(45 62 80 / 10%);
          background-color: var(--ion-color-light);
          border-radius: 4px;

          &:hover {
            border-color: rgba(45, 62, 80, 0.12);
            box-shadow: 0 3px 2px 0 rgb(45 62 80 / 12%);
          }
        }

        &.membership {
          padding: 12px 16px;

          &:hover {
            border-color: rgba(45, 62, 80, 0.12);
            box-shadow: 0 1px 8px 0 rgb(45 62 80 / 10%);
          }

          h5 {
            margin: 0;
            padding: 5px 0;

            font: {
              family: $fontBold;
              size: 1em;
            }

            span {
              &.active {
                border-color: rgba(3, 187, 62, 0.5);
                background-color: rgba(7, 168, 22, 0.15);
                padding: 0.2em 0.5em 0.15em;
                border-style: solid;
                border-width: 1px;
                border-radius: 3px;
                letter-spacing: 0.4px;
                margin-left: 8px;

                font: {
                  family: $fontBold;
                  size: 0.8em;
                }
              }
            }
          }

          .cencel {
            font: {
              family: $fontBold;
              size: 0.8em;
            }

            color: var(--ion-color-primary);
            cursor: pointer;
          }
        }
      }
    }

    .close {
      position: absolute;
      top: 30px;
      right: 24px;
      cursor: pointer;

      ion-icon {
        font-size: 32px;
        color: black;
      }

      @media (max-width: 767px) {
        top: 40px;
      }
    }
  }
}

//filter popover
.popover-filter {
  .popover-viewport {
    ion-list {
      padding: 0;

      ion-item {
        color: var(--ion-text-color);
      }
    }
  }
}

.group-aplicant {
  padding: 20px;

  h4 {
    margin: 0;

    &.sub {
      font: {
        family: $fontSemiBold;
      }
    }
  }

  ion-col {
    &.accept-desline {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;

      p {
        margin: 0;
      }

      ion-button {
        margin: 0;
        font-size: 16px;
      }
    }
  }
}

.masonry-list {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  grid-auto-columns: 1fr;
  grid-auto-rows: 20vw;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 20vw;

  @media (min-width: 1440px) {
    grid-auto-rows: 260px;
    grid-template-rows: 260px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-auto-rows: 40vw;
    grid-template-rows: 0;
  }

  .item {
    position: relative;
    border-radius: 10px;
    background-color: var(--ion-color-light);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: transform .4s, box-shadow .4s, -webkit-transform .4s;
    margin-inline: unset;
    margin: 0;

    &:hover {
      box-shadow: 0 13px 20px 2px rgb(0 0 0 / 30%);
      transform: scale(1.035);
      background-color: unset !important;
    }

    &::part(native) {
      height: 100%;
    }

    // Start 0
    &.masonry-item-0-0 {
      grid-column: span 2;
      grid-row: span 2;
    }

    &.masonry-item-0-1,
    &.masonry-item-0-2 {
      grid-column: span 1;
      grid-row: span 1;
    }

    &.masonry-item-0-3 {
      grid-column: span 2;
      grid-row: span 1;
    }

    // End 0

    // Start 1
    &.masonry-item-1-0 {
      grid-column: span 2;
      grid-row: span 1;
    }

    &.masonry-item-1-1 {
      grid-column: span 2;
      grid-row: span 2;
    }

    &.masonry-item-1-2,
    &.masonry-item-1-3 {
      grid-column: span 1;
      grid-row: span 1;
    }

    // End 1

    // Start 2
    &.masonry-item-2-0,
    &.masonry-item-2-1 {
      grid-column: span 1;
      grid-row: span 1;
    }

    &.masonry-item-2-2 {
      grid-column: span 2;
      grid-row: span 2;
    }

    &.masonry-item-2-3 {
      grid-column: span 2;
      grid-row: span 1;
    }

    // End 2

    // Start 3
    &.masonry-item-3-0 {
      grid-column: span 2;
      grid-row: span 2;
    }

    &.masonry-item-3-1 {
      grid-column: span 1;
      grid-row: span 2;
    }

    &.masonry-item-3-2,
    &.masonry-item-3-3 {
      grid-column: span 1;
      grid-row: span 1;
    }

    img {
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
      object-fit: cover;
    }

    .content-img {
      position: absolute;
      bottom: 12px;
      left: 12px;
      right: 12px;
      color: var(--ion-color-light);
      cursor: pointer;

      h4 {
        margin-bottom: 5px;
        text-shadow: 0 1px 8px var(--ion-color-black);
        font-size: 24px;
        line-height: 28px;
      }

      p {
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-shadow: 0 1px 6px var(--ion-color-black);

        font: {
          family: $fontMedium;
        }
      }

      @media (max-width: 992px) {
        bottom: 12px;
        left: 12px;
        right: 12px;

        h4 {
          margin-bottom: 0;
          font-size: 18px !important;
          line-height: 1.2 !important;
        }

        p {
          font-size: 14px !important;
        }
      }

      @media (max-width: 480px) {
        bottom: 8px;
        left: 8px;
        right: 8px;

        h4 {
          margin-bottom: 0;
          line-height: 20px !important;
        }

        p {
          font-size: 14px !important;
          line-height: 16px !important;
        }
      }
    }
  }
}

p {
  &.xlarge {
    font-size: 2em;
    line-height: 1.4em;

    &.quote {
      font: {
        size: 24px;
        family: $fontBold;
        style: italic;
      }
    }
  }
}

// stories
.section-stories {
  max-width: 1248px;
  margin: 40px auto 0 auto;
  padding: 0 24px;

  @media (max-width: 1232px) {
    padding: 0 24px;
  }

  @media (max-width: 768px) {

    p {
      &.xlarge {
        font-size: 1.4em;
        line-height: 1.4em;
      }
    }
  }

  .max-w-800 {
    max-width: 800px;
    margin: 8px auto;
    padding: 0;

    @media (max-width: 848px) {
      margin: 8px;
    }

    p {
      margin: 0 0 16px 0;
    }

    h1 {
      margin: 0 0 32px 0;
    }
  }

  // app-content-block-text {
  //   padding: 0 16px !important;
  // }
}

app-posts {
  .comment-card,
  .post-card {
    margin-bottom: 20px;

    @media screen and (max-width: 576px) {
      margin-bottom: 16px;
    }
  }
}

.text-ellipsis--3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.list-smallerize {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  li {
    list-style-position: inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  li:nth-child(n + 4) {
    display: none;
  }
}

.ion-item-link {
  --border-radius: 4px;
  --background-hover-opacity: 0.1;
  font-family: 'Inter-Medium';
  cursor: pointer;
  &:hover {
    color: var(--ion-color-primary);
  }

  &.share {
    cursor: pointer;
    --min-height: 38px;
    &:hover {
      color: var(--ion-color-primary);
    }
  }
}

.new-location {
  ion-icon {
    font-size: 8rem;
  }
}

.loader-with-header-and-footer {
  height: calc(100% - 420px);
}

//project
.home {
  &.project {
    .main-content {
      h1 {
        &.max-w-800 {
          padding: 24px 24px 0 24px;
        }
      }

      .banner-buttons {
        max-width: 1232px;
        margin: 0 auto;
        padding: 8px 8px;

        @media screen and (max-width: 768px) {
          padding: 8px 0;
        }
        
        

        button {
          margin: 12px 16px;
        }
      }

      .max-w-1200 {
        max-width: 1248px;
        margin: 0 auto;
        padding: 0;

        .max-w-800 {
          padding-top: 0;
          padding-bottom: 0;
        }

        h2 {
          font-size: 48px;
          line-height: 52px;
          margin-top: 0;

          @media screen and (max-width: 768px) {
            font-size: 32px !important;
            line-height: 38px !important;
          }
        }

        ion-grid {
          ion-row {
            ion-col {
              .main {
                padding: 48px;

                @media screen and (max-width: 768px) {
                  padding: 24px 24px 0 24px;
                }

                &.href {
                  .content {
                    padding-top: 8px;
                  }

                  @media screen and (max-width: 768px) {
                    padding: 8px 24px;
                  }
                }
              }


              img {
                height: 190px;
                text-align: center;
                margin-bottom: 24px;
              }

              h5 {
                font: {
                  family: $fontBold;
                  size: 1.125em;
                }

                line-height: 1.4em;
                margin-top: 0;
              }

              a {
                font: {
                  family: $fontBold;
                  size: 1.125em;
                }

                text-decoration: underline;
                color: var(--ion-text-color);
                cursor: pointer;
                margin-bottom: 5px;

                &:hover {
                  color: var(--ion-color-primary);
                }
              }

              .content {
                font-size: 16px;
              }

              &:first-child {
                .main {
                  padding-left: 24px;
                }
              }
              &:last-child {
                .main {
                  padding-right: 24px;
                }
              }
            }

            &.melding {
              height: auto;
              padding: 40px 0;

              ion-col {
                .main {
                  padding: 0;

                  h2 {
                    padding-left: 0;
                  }

                  a {
                    text-decoration: none;
                    color: var(--ion-color-light);
                  }
                }

                img {
                  height: 100%;
                  object-fit: cover;
                  padding: 0;
                }
              }
            }
          }
        }

        &.buttons {
          padding: 0 8px;

          h2 {
            padding: 0 24px;
          }
          ion-grid {

            ion-row {
              height: auto;

              ion-col {
                padding: 16px;
                border-radius: 12px;

                @media screen and (max-width: 768px) {
                  padding: 16px 16px;
                }

                a {
                  
                  &:hover {
                    h5 {
                      color: var(--ion-text-color);
                    }
                  }
                }

                .main-btn {
                  background: var(--ion-color-tertiary);
                  border-radius: 12px !important;
                  transition: transform .3s, box-shadow .2s, -webkit-transform .3s;
                  cursor: pointer;
                  height: 100%;

                  &:hover {
                    transform: scale(1.035);
                  }
                  

                  .main {
                    border-radius: 12px !important;
                    padding: 0 !important;
                  }
                  .img {
                    width: 100%;
                    text-align: center;

                    img {
                      height: 232px;
                      padding: 0;
                      margin-bottom: 0 !important;
                      width: 100%;
                      object-fit: cover;
                      border-radius: 10px 10px 0 0;

                      @media screen and (max-width: 480px) {
                        height: 230px;
                      }
                    }
                    
                  }

                  .btn-text {
                    padding: 24px 32px 32px 32px;
                    
                    @media screen and (max-width: 480px) {
                      padding: 16px 16px 24px 16px;

                      h5 {
                        font-size: 18px !important;
                        line-height: 22px !important;
                      }
                    }

                    .content {
                      p {
                        font: {
                          family: $fontRegular;
                          size: 16px;
                        }
  
                        color: var(--ion-text-color);
                        margin-bottom: 24px;
  
                        @media screen and (max-width: 480px) {
                          font-size: 14px !important;
                          line-height: 20px !important;
                          margin-bottom: 8px;
                        }
                      }
                    }

                    a {
                      font: {
                        family: $fontRegular;
                        size: 16px;
                      }

                      color: var(--ion-color-primary);
                      text-decoration: none !important;
                      @media screen and (max-width: 480px) {
                        font-size: 14px !important;
                        line-height: 20px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.search-location {
          max-width: 848px;
          margin: 0 auto;
          padding: 24px;

          .subtitle {
            font-size: 24px;
            line-height: 33px;
          }

          .date {
            font-size: 12px;
            line-height: 18px;
            color: var(--ion-color-medium);
          }

          ion-row {
            padding-left: 0 !important;
            padding-right: 0 !important;

            ion-col {
              &.search-result-card {
                padding: 0;
                box-shadow: none;
                background-color: unset;

                font: {
                  family: $fontRegular;
                  size: 24px;
                }

                color: var(--ion-color-dark);
                line-height: 30px;

                .count {
                  font: {
                    family: $fontRegular;
                    size: 24px;
                  }

                  color: var(--ion-color-dark);
                  line-height: 30px;
                  border: none;
                }
                .like {
                  width: 42px;
                  height: 42px;
                  border-radius: 42px;
                  border: none;

                  ion-icon {
                    font-size: 34px;
                  }

                  .badge {
                    width: 18px;
                    height: 18px;
                    border-radius: 18px;
                    top: 2px;
                    right: -2px;
                    font-size: 10px;
                    animation-delay: 0.5s;
                  }
                }

                @media screen and (max-width: 768px) {
                  font: {
                    size: 18px;
                  }

                  line-height: 20px;

                  .count {
                    font: {
                      size: 18px;
                    }
  
                    line-height: 20px;
                  }
                }
              }
            }
          }

          @media screen and (max-width: 768px) {
            padding: 24px;
            .subtitle {
              font-size: 18px !important;
              line-height: 25px !important;
            }
          }

          .searchWrapper {
            ion-searchbar {
              --background: #F2F2F2;
              --border-radius: 2px;
              --box-shadow: none;
              min-height: 72px;
            }
          }
        }
      }

      .max-w-800 {
        max-width: 848px;
        margin: 0 auto;
        padding: 64px 24px;

        &.content {
          padding: 24px;
          p {
            margin: 0;
            color: var(--ion-color-dark);
            font-size: 24px;
            line-height:33px;

            @media screen and (max-width: 768px) {
              font-size: 18px;
              line-height: 25px;
            }
          }
        }


        ion-grid {
          ion-row {
            height: auto;
            padding: 0;

            ion-col {
              .main {
                padding: 0 56px;

                h2 {
                  margin-top: 0;
                }

                .content {
                  a {
                    color: var(--ion-text-color);
                    text-decoration: underline;

                    &:hover {
                      color: var(--ion-color-primary);
                    }
                  }
                }
              }

              img {
                height: 400px;
                width: 100%;
                object-fit: cover;
                padding: 0 0 0 32px;

                &.left {
                  padding: 0 32px 0 0;
                }

                @media screen and (max-width: 768px) {
                  padding: 0;

                  &.left {
                    padding: 0;
                  }
                }

                @media screen and (max-width: 768px) {
                  height: 224px;
                }
              }
            }
          }
        }

        @media screen and (max-width: 768px) {
          padding: 24px;

          ion-grid {
            ion-row {
              height: auto;
              padding: 0;

              ion-col {
                .main {
                  padding: 0;

                  &.right {
                    padding: 0;
                  }
                }

                &.order {
                  order: 2;
                  margin-top: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//app switch components
app-content-block-text-with-image {
  .max-w-800 {
    max-width: 1136px !important;
  }
}

app-content-blocks {
  & > * {
    display: block;
    margin-bottom: 60px;

    &:empty {
      display: none;
    }
  }

  h1 {
    margin-top: 0;
  }

  table {
    border-spacing: 16px;
    border-collapse: separate;
    margin: 0 -16px;
  }
}

app-content-block-cubes {
  ion-grid {
    ion-row {
      &.three {
        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr;
        }
      }

      &.four {
        @media screen and (max-width: 940px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 640px) {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

app-content-block-large-buttons {
  .max-w-1200 {
    max-width: 1248px !important;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }

  .buttons {
    padding: 0 8px;

    h2 {
      padding: 0 16px;
    }
    ion-grid {

      ion-row {
        height: auto;

        ion-col {
          padding: 16px;
          border-radius: 12px;

          @media screen and (max-width: 768px) {
            padding: 16px 16px;
          }

          h5 {
            font-family: $fontBold;
            color: var(--ion-text-color);
            text-decoration: none;
          }

          .link {
            text-decoration: underline;
          }

          .main-btn {
            background: var(--ion-color-tertiary);
            border-radius: 12px !important;
            transition: transform .3s, box-shadow .2s, -webkit-transform .3s;
            cursor: pointer;
            height: 100%;

            &:hover {
              transform: scale(1.035);
            }


            .main {
              border-radius: 12px !important;
              padding: 0 !important;
            }
            .img {
              width: 100%;
              text-align: center;

              img {
                height: 232px;
                padding: 0;
                margin-bottom: 0 !important;
                width: 100%;
                object-fit: cover;
                border-radius: 10px 10px 0 0;

                @media screen and (max-width: 480px) {
                  height: 230px;
                }
              }

            }

            .btn-text {
              padding: 24px 32px 32px 32px;

              @media screen and (max-width: 480px) {
                padding: 16px 16px 24px 16px;

                h5 {
                  font-size: 18px !important;
                  line-height: 22px !important;
                }
              }

              .content {
                p {
                  font: {
                    family: $fontRegular;
                    size: 16px;
                  }

                  color: var(--ion-text-color);
                  margin-bottom: 24px;

                  @media screen and (max-width: 480px) {
                    font-size: 14px !important;
                    line-height: 20px !important;
                    margin-bottom: 8px;
                  }
                }
              }

              a {
                font: {
                  family: $fontRegular;
                  size: 16px;
                }

                color: var(--ion-color-primary);
                text-decoration: none !important;
                @media screen and (max-width: 480px) {
                  font-size: 14px !important;
                  line-height: 20px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

app-content-block-buttons {
  .max-w-1200 {
    max-width: 1200px;
    margin: 0 auto;
  }

  .buttons {
    margin: 12px 0;

    ion-col {
      padding: 8px;
    }
  }
}

//popover
.select-options  {
  ion-list {
    padding: 12px 0;
    @media screen and (max-width: 480px) {
      padding: 8px 0;
    }
    ion-item {
      padding: 2px 12px;
      --border-radius: 8px;
      --inner-border-width: 0px;
      @media screen and (max-width: 480px) {
        padding: 0px 8px;
      }

      ion-radio {
        opacity: 1 !important; 
      }

      ion-label {
        white-space: unset !important;
        margin-right: 18px;
      }
    }

  }
}

app-onboarding-modal {
  ion-grid {
    ion-col {
      &.main {
        padding: 24px 24px 24px 24px !important;
      }
    }
  }
}

.characteristics-grid {
  font: {
    family: $fontRegular;
    size: 18px;
  }

  ion-row {
    padding: 16px 0;
    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-background-color-step-150, rgba(0, 0, 0, 0.13))));
  }

  .label {
    color: var(--ion-color-medium);
  }
}

.modal-stacked-backdrop {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.buy-budget-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .buy-budget-text {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 4px;
    padding: 4px 8px;
    display: inline-block;
    margin: 4px 0;
  }
}

.ion-item-input {
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 4px;
  min-height: 48px;
  margin-top: 5px;
  --padding-start: 12px;
  --padding-end: 12px;

  font: {
    family: $fontMedium;
    size: 16px;
  }
}

.cookie-consent-backdrop {
  opacity: 0.7;
  z-index: 999998;
}

.cookie-consent-banner {
  font: {
    family: $fontRegular;
    size: 16px;
  };
  line-height: 1.5em;

  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  z-index: 999999;
  padding: 1.2em;
  color: var(--ion-color-light);
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;

  @media (max-width: 767px) {
    left: 0;
    bottom: 0;
    max-width: none;
    width: 100%;
    border-radius: 0;
  }

  form {
    max-width: 800px;
    margin: 0 auto;
  }

  .cookie-specific-preference {
    background: transparent;

    ion-item {
      --background: transparent;
      color: var(--ion-color-light);
      --color: var(--ion-color-light);
      --min-height: 32px;

      ion-checkbox {
        margin: 0 8px 0 0;
      }

      ion-label {
        margin: 0;
      }
    }
  }

  ion-grid {
    ion-row {
      margin: 0 -6px;
      @media (max-width: 767px) {
        margin: 0;
      }

      ion-col {
        padding: 6px;
        @media (max-width: 767px) {
          padding: 6px 0;
        }
      }
    }
  }
}

.ion-accordion-toggle-icon {
  color: var(--ion-text-color);
}

.max-w-900 {
  max-width: 900px;
  margin: 0 auto;
}

.ion-no-text-decoration {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.ion-transform-effect {
  transition: transform .3s, box-shadow .2s, -webkit-transform .3s;

  &:hover {
    transform: scale(1.035);
  }
}

app-timeline .news-card .card-body p br {
    display: none;
}

ion-toast[color="primary"] {
  &::part(button) {
    color: var(--ion-color-primary);
    background: var(--ion-color-primary-contrast);
    border: solid var(--ion-color-primary) 2px;
    font-family: var(--ion-font-family-bold);
    font-size: 16px;
    text-transform: none;
    border-radius: 50px;
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover {
      color: var(--ion-color-primary-contrast);
      background: var(--ion-color-primary);
      border: solid var(--ion-color-primary-contrast) 2px;
    }
  }
}

.reason-container {
  margin: 16px 0;

  p {
    font-size: 14px;
    margin: 0;
  }
}

.scrollable-anchor-container {
  display: flex;
  gap: 24px;
  padding: 8px 0;
  align-items: center;
  overflow-x: auto;
  font: {
    family: $fontSemiBold;
    size: 18px;
  }

  &.bold {
    font: {
      size: 20px;
    }
  }

  a {
    line-height: 20px;
    padding: 2px 0;
    color: var(--ion-color-medium);

    text-decoration: none;

    &:hover {
      color: var(--ion-text-color);
      border-bottom: 2px solid;
    }

    &.active {
      color: var(--ion-text-color);

      cursor: default;
      border-bottom: 2px solid;
    }

    span {
      margin-left: 8px;
    }
  }

  @media (max-width: 992px) {
    gap: 16px;
  }
}

.max-w-600 {
  max-width: 600px;
}

.max-w-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.max-w-1248 {
  max-width: 1248px;
  margin: 0 auto;

  &.with-default-padding {
    padding: 0 24px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

hr {
  background: var(--ion-color-light-shade);
}

figure {
  margin: 1rem 0;

  &.image {
    display: flex;
    justify-content: center;

    &.image-style-side {
      justify-content: end;
    }
  }
}

.contact-grid {
  margin-top: 30px;

  ion-row {
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;

    ion-col {
      padding: 0;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
}

.social-icons {
  display: flex;
  gap: 8px;

  a {
    text-decoration: none !important;
    font-size: 2.375rem;

    -webkit-transition:  -webkit-transform .2s ease-out;
    -moz-transition: -moz-transform .2s ease-out;
    -o-transition: -o-transform .2s ease-out;
    -ms-transition: -ms-transform .2s ease-out;
    transition: transform .2s ease-out;

    &:hover {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }

    &.small {
      font-size: 2rem;
      height: 2rem;
    }

    &.large {
      font-size: 3rem;
      height: 3rem;
    }
  }
}

.share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  text-align: center;

  h2,
  h3 {
    margin: 0;
    color: var(--ion-text-color);
  }

  h4 {
    color: var(--ion-color-gray);
    margin: 0;
    font: {
      size: 1rem;
      family: $fontMedium;
    }
  }

  .icon {
    font: {
      size: 3rem;
    }
  }

  .social-icons {
    justify-content: center;
  }

  .copy-to-clipboard {
    word-break: break-all;
    --border-radius: 4px;
    --inner-padding-top: 12px;
    --inner-padding-bottom: 12px;

    ion-icon {
      margin: 0 16px 0 0;
    }
  }
}

.profile-incomplete-accordion-group {
  .ion-accordion-toggle-icon {
    -webkit-margin-end: 8px;
    margin-inline-end: 8px;
    color: var(--ion-color-danger);
  }

  ion-item {
    --padding-start: 0;
  }

  p {
    margin: 0 0 0 8px;
    color: var(--ion-color-danger);
  }
}

.mapboxgl-popup-content, .maplibregl-popup-content {
  padding: 0;
  border-radius: 8px;

  ion-card.card-group img.header-img {
    height: 160px;

    @media (max-width: 480px) {
      height: 120px;
    }
  }
}

.mapboxgl-marker, .maplibregl-marker {
  width: 40px;
  height: 40px;
  color: var(--ion-color-primary);
}

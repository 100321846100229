h3 {
  line-height: 1.3;
}

b, strong {
  font: {
    family: $fontBold;
  }
}

p {
  font: {
    family: $fontRegular;
    size: 16px;
  }

  line-height: 20px;

  &.slide-content {
    font: {
      size: 1.4em;
    };

    line-height: 1.4em;
    color: var(--ion-color-medium);
  }

  &.subtitle {
    font: {
      size: 1.4em;
    };

    line-height: 1.4em;
    color: var(--ion-color-medium);
  }

  &.dark {
    font: {
      family: $fontBold;
      size: 16px;
    }

    color: var(--ion-color-medium);

    &.black {
      color: var(--ion-text-color);
    }

    &.sub {
      font-size: 16px;
    }
  }

  &.title-gnr {
    font-size: 14px;
    color: var(--ion-color-primary);
  }

  &.link {
    span {
      cursor: pointer;
      color: var(--ion-color-primary);
      border-style: none none solid;
      border-bottom-width: 1px;
      border-bottom-color: var(--ion-color-primary);

      &.bold {
        font: {
          family: $fontBold;
        }
      }
    }
  }

  &.detail-header-subtitle {
    font-size: 2em;
    color: var(--ion-color-light);
    line-height: 1em;
    margin: 0;
    text-shadow: 0 1px 4px rgb(0 0 0 / 40%);
  }

  small {
    font: {
      family: $fontRegular;
      size: 14px;
    };
  }
}

ion-breadcrumbs {
  flex-wrap: wrap;
}

.content {
  font: {
    family: $fontRegular;
    size: 18px;
  }

  line-height: 1.5em;
  white-space: pre-line;

  ol,
  ul {
    padding-left: 19px;
    li {
      font: {
        family: $fontRegular;
        size: 18px;
      }

      line-height: 1.5em;
      padding-bottom: 5px;
    }
  }

  p {
    font-size: 18px;
    line-height: 28px !important;
  }
}

.text-large-paragraph {
  p {
    font-size: 1.8em;
    color: var(--ion-text-color);
    line-height: 1.2em;
    margin: 0;
  }
}

.subtitle {
  font: {
    family: $fontRegular;
    size: 1.4em;
  };

  line-height: 1.4em;
  color: var(--ion-color-medium);

  p {
    font: {
      family: $fontRegular;
      size: 1em;
    };

    line-height: 1.4em;
  }
}

.subtitle-big {
  font: {
    family: $fontRegular;
    size: 1.45em;
  };

  line-height: 1.4em;
  color: var(--ion-text-color);
}

h1 {
  font: {
    family: $fontBold;
    size: 3rem;
  };

  line-height: 1em;

  &.big {
    font-size: 3.5em;
    line-height: 1.2em
  }

  &.detail-header-title {
    font-size: 6rem;
    line-height: 1em;
    color: var(--ion-color-light);
  }
}

h2 {
  font: {
    family: $fontBold;
    size: 32px;
  };

  line-height: 38px;

  &.slide-title {
    font: {
      size: 2.4em;
    };

    line-height: 1.2em;
    margin: 0;
  }

  &.banner {
    font-size: 42px;
  }

  &.notify {
    font: {
      family: $fontSemiBold;
    };
    font-size: 38px;
    line-height: 42px;
  }
}

h4 {
  font: {
    family: $fontBold;
    size: 1.2em;
  };

  line-height: 1.4;

  &.big {
    font-size: 1.4em;
  }
}

h5 {
  font: {
    family: $fontMedium;
  }

  &.comment {
    color: var(--ion-color-medium);
  }

  &.characteristics-subtitle {
    font: {
      family: $fontBold;
    }
    margin: 24px 0 0 0;
  }
}

@media (max-width: 480px) {
  h1 {
    font: {
      size: $font28;
    }

    line-height: 30px !important;

    &.big {
      font: {
        size: $font30 !important;
      }

      line-height: 30px;
    }
  }

  h2 {
    font-size: 24px !important;
    line-height: 28px !important;
    &.slide-title {
      font: {
        size: 28px;
      };
    }

    &.banner {
      font-size: 32px !important;
      line-height: 38px !important;
    }
  }

  h3 {
    line-height: 24px !important;
  }


  p {
    line-height: 1.2em !important;
    &.slide-content {
      font: {
        size: 18px;
      };
    }
  }

  .text-large-paragraph {
    p {
      font-size: 1.6em;
    }
  }

  .subtitle {
    font-size: 1.2em;
  }

  .content {
    font-size: 16px;
    line-height: 26px;

    p {
      font-size: 16px;
      line-height: 24px !important;
    }

    h4 {
      margin-bottom: 4px;
    }
  }

  .subtitle-big {
    font-size: 1.2em;
  }

  h5 {
    font: {
      family: $fontMedium;
      font-size: 12px;
    }
  
    &.comment {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 991px) {
  h1 {
    &.detail-header-title {
      font-size: 4rem;
    }
  }

  .text-large-paragraph {
    p {
      font-size: 1.4em;
    }
  }
}

@media screen and (max-width: 767px) {
  h1 {
    &.detail-header-title {
      font-size: 3.2rem !important;
      line-height: 1em !important;
    }
  }
}

label {
  &.label-default {
    display: inline-block;
    margin-bottom: 8px;

    color: var(--ion-text-color);

    font: {
      family: $fontBold;
      size: 1rem;
    }

    small {
      font: {
        family: var(--ion-font-family);
        size: 0.875rem;
      }
    }
  }

  &.label-color-medium {
    color: var(--ion-color-medium);
  }
}

a {
  color: var(--ion-color-primary);
  text-decoration: underline;

  &.bold {
    font-family: $fontMedium;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    border-bottom: 1px solid var(--ion-color-primary);
  }

  &.anchor-default-text-hover-underline {
    color: var(--color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      text-decoration: underline;
    }
  }
}

.bold {
  font-family: $fontBold;
}

.font-regular-18px {
  font-family: $fontRegular;
  font-size: 18px;
}

.text-between-horizontal-lines {
  overflow: hidden;
  text-align: center;

  &::before,
  &::after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &::before {
    right: 0.5em;
    margin-left: -50%;
  }

  &::after {
    left: 0.5em;
    margin-right: -50%;
  }
}

i {
  &.icon {
    font: {
      style: unset;
    }
  }
}

input {
  color-scheme: light;
  color: var(--ion-text-color);
  background: var(--ion-color-light);
  border: 2px solid var(--ion-input-border-color);
  border-radius: 4px;
  padding: 4px 8px;
  outline-color: var(--ion-input-hover-border-color);

  @media (any-hover: hover) {
    &:hover {
      border-color: var(--ion-input-focus-border-color);
    }
  }

  &:focus {
    border: none;
    border-radius: 0;
    outline-color: var(--ion-input-focus-border-color);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
}

.slider {
  padding-top: 70px !important;
  @media (max-width: 480px) {
    padding-top: 60px !important;
    padding-bottom: 0;
  }
  .swiper {
    width: 100%;
    height: 100%;
    .swipe-img-div {
      width: 100%;
    }
    img {
      display: block;
      width: 100% !important;
      height: 15vw !important;
      object-fit: cover;
      border-radius: 4px;
      max-height: 246px !important;

      @media (max-width: 768px) {
        height: 23vw !important;
      }

      @media (max-width: 480px) {
        height: 33vw !important;
      }
    }

    .swiper-title {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;

      font-family: $fontBold;
      font-size: 1.3rem;
      line-height: 1.2em;
      text-shadow: 0 1px 6px var(--ion-color-black);
      color: var(--ion-color-light);
      text-align: left;
      margin: 0;
      padding: 0 16px;

      @media (max-width: 992px) {
        bottom: 16px;

        line-height: 1em;
        font-size: 1rem;
      }

      @media (max-width: 480px) {
        bottom: 12px;

        font-size: 14px;
        line-height: 16px;
      }
    }

    .swiper-slide {
      cursor: pointer;
      margin-top: 4px;
      margin-bottom: 16px;
      transition: box-shadow .2s,transform .3s,-webkit-transform .3s;
      &:hover {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 6%), 0 4px 8px 0 rgb(0 0 0 / 10%);
        transform: scale(1.035); 
      }

      @media (max-width: 480px) { 
        &:hover {
          box-shadow: unset !important;
          transform: unset !important; 
        }
      }
    }

    .swiper-button-next {
      top: 24px;
      border: 1px solid var(--ion-color-light-shade);
        border-radius: 50%;
        width: 48px;
        height: 48px;

        &:after {
          color: black;
          font-size: 16px;
          font-weight: bold;
          padding-left: 2px;
        }

        &:hover {
          background-color: var(--ion-color-light-shade);
        }

      &.swiper-button-disabled {
        display: none;
      }
    }

    .swiper-button-prev {
      top: 24px;
      right: 75px;
      left: auto;
      border: 1px solid var(--ion-color-light-shade);
        border-radius: 50%;
        width: 48px;
        height: 48px;

        &:after {
          color: black;
          font-size: 16px;
          font-weight: bold;
          padding-right: 2px;
        }

        &:hover {
          background-color: var(--ion-color-light-shade);
        }

      &.swiper-button-disabled {
        display: none;
        
      }
    }
  }
}

.slider-home {
  max-width: 1200px;
  margin: 0 auto;

  .swiper {
    width: 100%;
    height: 100%;
    position: relative;

    .swiper-slide {
      background-position: center;
      background-size: cover;
      justify-content: flex-start;
      
      .content-slider {
        text-align: left;
        position: absolute;
        background: rgba(255, 255, 255, 0.6);
        width: 45%;
        margin: 32px;
        padding: 32px;
        border-radius: 4px;
        p {
          font-size: 1.123em;
          color: var(--ion-color-primary);
          margin-top: 0;
          line-height: 1.4em;
        }
        .content {
          font-size: 1.125rem;
          line-height: 1.4em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;

          p {
            color: var(--ion-text-color);
          }
        }
      }

      img {
        display: block;
        height: 60vh;
        width: 100%;
        object-fit: cover;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%), 0 1px 3px 0 rgb(0 0 0 / 10%);
        border: 1px solid #eaeaea;
        border-radius: 8px;
      }

      @media (max-width: 992px) {
        .content-slider {
          width: 50%;
        }
        img {
          height: 65vh;
        }
      }

      @media (max-width: 767px) {
        .content-slider {
          width: 60%;
          h4 {
            line-height: 1.3em;
          }
  
          p {
            font-size: 1.1em;
            line-height: 1.3em;
          }

          .content {
            font-size: 1.1em;
            -webkit-line-clamp: 5;
            line-height: 1.3em;
          }
        }

        img {
          height: 65vh;
        }
      }

      @media (max-width: 480px) {
        .content-slider {
          width: 86%;
          padding: 16px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          max-height: 90%;

          h4 {
            line-height: 1.3em;
          }
  
          p {
            font-size: 1.1em;
            line-height: 1.3em;
          }
  
          .content {
            font-size: 1.1em;
            -webkit-line-clamp: 4;
            line-height: 1.3em;
          }
        }

        img {
          height: 62vh;
        }
      }

    }

    .swiper-button-next {
      bottom: 5px;
      top: auto;
      border: 1px solid var(--ion-color-light-shade);
        border-radius: 50%;
        width: 48px;
        height: 48px;
  
        &:after {
          color: black;
          font-size: 16px;
          font-weight: bold;
          padding-left: 2px;
        }
  
        &:hover {
          background-color: var(--ion-color-light-shade);
        }
  
      &.swiper-button-disabled {
        // display: none;
      }
    }
  
    .swiper-button-prev {
      bottom: 5px;
      top: auto;
      right: 75px;
      left: auto;
      border: 1px solid var(--ion-color-light-shade);
        border-radius: 50%;
        width: 48px;
        height: 48px;
  
        &:after {
          color: black;
          font-size: 16px;
          font-weight: bold;
          padding-right: 2px;
        }
  
        &:hover {
          background-color: var(--ion-color-light-shade);
        }
  
      &.swiper-button-disabled {
        // display: none;
      }
    }

    &.with-navigation {
      padding: 0 0 70px;
    }
  }

  &.featured {
    @media (max-width: 767px) {
      padding: 16px;
    }
    .swiper {
      padding: 12PX 0 20px 0 !important;

      img {
        max-height: 320px;
      }

      .content-slider {
        max-height: 290px;
        background: var(--ion-color-light);

        h4 {
          margin-top: 0;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
        }

        .content {
          -webkit-line-clamp: 2;

          @media (max-width: 767px) {
            font-size: 16px;
            -webkit-line-clamp: 4;
          }
        }
      }
    }
  }

  &.project {
    .swiper {
      img {
        height: 520px;

        @media (max-width: 480px) {
          height: 420px;
        }
      }

      .content-slider {
        height: auto;
        // background: rgba(255, 255, 255, 0.6);
        background: var(--ion-color-light);
        margin: 32px 32px 32px 100px;
        max-width: 420px;
        p {
          font-size: 16px;
        }

        h4 {
          margin-top: 0;
          font-size: 42px;
          line-height: 48px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
        }

        .content {
          -webkit-line-clamp: 3;
        }

        @media (max-width: 767px) {
          height: auto;
          background: var(--ion-color-light);
          margin: 32px;
          max-width: 420px;
          p {
            font-size: 14px;
          }
  
          h4 {
            margin-top: 0;
            font-size: 32px;
            line-height: 38px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
  
          .content {
            -webkit-line-clamp: 3;
            font-size: 16px;
          }
        }

        @media (max-width: 480px) {
          height: auto;
          background: var(--ion-color-light);
          margin: 24px;
          max-width: 100%;
          padding: 24px;
          bottom: 0;
          p {
            font-size: 12px;
          }
  
          h4 {
            margin-top: 0;
            font-size: 32px;
            line-height: 38px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
  
          .content {
            -webkit-line-clamp: 3;
            font-size: 14px;
          }
        }
      }
    }
  }
}


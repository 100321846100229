.ck {
  --ck-focus-ring: 2px solid var(--ion-input-focus-border-color);
  --ck-border-radius: 6px;
  --ck-color-base-border: var(--ion-input-border-color);
  --ck-color-toolbar-border: var(--ion-input-border-color);

  @media (any-hover: hover) {
    &:hover {
      --ck-color-base-border: var(--ion-input-focus-border-color);
    }
  }

  &.ck-toolbar {
    border-width: 2px !important;
    border-bottom: none !important;
  }

  &.ck-editor__editable_inline {
    border-width: 2px !important;
  }

  &.ck-powered-by-balloon {
    display: none !important;
  }

  &.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 0 !important;
    border-left-width: 2px !important;
  }
}

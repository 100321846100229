/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import 'maplibre-gl/dist/maplibre-gl.css';

// Ng select
@import "@ng-select/ng-select/themes/default.theme.css";

// Extra styling pagina's
@import "theme/globals.scss";
@import "theme/fonts.scss";
@import "theme/typography.scss";
@import "theme/ionic-elements";
@import "theme/layout.scss";
@import "theme/header.scss";
@import "theme/ckeditor";
@import "theme/maplibre-gl";
@import "theme/components/buttons.scss";
@import "theme/components/tabs.scss";
@import "theme/components/cards.scss";
@import "theme/components/auth.scss";
@import "theme/components/profile-stappen.scss";
@import "theme/components/side-menu.scss";
@import "theme/components/slider.scss";
@import "theme/components/footer.scss";
@import "theme/components/detail-page.scss";
@import "theme/components/modals.scss";
@import "theme/components/ng-select.scss";
@import "theme/components/memberstack.scss";

//swiper
@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/keyboard";
@import "swiper/scss/pagination";
@import "swiper/scss/scrollbar";
@import "swiper/scss/effect-cube";
@import "swiper/scss/effect-cards";
@import "swiper/scss/effect-coverflow";
@import "swiper/scss/zoom";
@import "@ionic/angular/css/ionic-swiper";
@import "swiper/css/bundle";

//animation.CSS
@import "animate.css";

$font10: 0.625rem;
$font12: 0.75rem;
$font13: 0.8125rem;
$font14: 0.875rem;
$font16: 1rem;
$font18: 1.125rem;
$font20: 1.25rem;
$font22: 1.375rem;
$font24: 1.5rem;
$font26: 1.625rem;
$font28: 1.75rem;
$font30: 1.875rem;
$font32: 2rem;
$font34: 2.125rem;
$font36: 2.25rem;
$font38: 2.375rem;
$font40: 2.5rem;
$font45: 2.8125rem;
$font48: 3rem;
$font50: 3.125rem;
$font60: 3.75rem;
$font80: 5rem;

@mixin shadow($property) {
  -webkit-box-shadow: $property;
  -moz-box-shadow: $property;
  box-shadow: $property;
}

@mixin textshadow($property) {
  -webkit-text-shadow: $property;
  -moz-text-shadow: $property;
  text-shadow: $property;
}

@mixin zindex($property) {
  z-index: $property;
}

$sizeamounts: (8, 10, 12, 14, 15, 16, 17, 18, 20, 22, 24, 26, 28, 30, 40, 50, 60, 70, 80);

@each $size in $sizeamounts {
  .icon-#{$size}::before,
  .icon-#{$size}::after {
    font-size: #{$size}px !important;
  }
  .icon-#{$size} {
    line-height: #{$size}px;
  }
}

$marginamounts: (0, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 20, 24, 25, 30, 32, 35, 40, 50, 60, 70, 80);

@each $size in $marginamounts {
  .margin-top-#{$size} {
    margin-top: #{$size}px !important;
  }

  .margin-right-#{$size} {
    margin-right: #{$size}px !important;
  }

  .margin-bottom-#{$size} {
    margin-bottom: #{$size}px !important;
  }

  .margin-left-#{$size} {
    margin-left: #{$size}px !important;
  }
}

.profile-stappen {
  width: 100%;
  height: 100vh;
  padding-top: 0;

  ion-grid {
    ion-row {
      ion-col {
        &.results {
          padding: 48px;
          --overflow-y: scroll;
          position: absolute;
          top: 0;
          right: 0;

          @media (max-width: 480px) {
            padding: 48px 16px;
          }

          .avatar {
            img {
              width: 72px;
              height: 72px;
              border: 1px solid var(--ion-color-light-shade);
              border-radius: 100px;
            }

            h2 {
              margin: 8px 0 0 0;
              font: {
                size: 24px;
              }
            }

            p {
              margin: 0;
              color: var(--ion-color-gray);
            }
          }

          .results-list {
            gap: 12px;
            position: relative;
            display: flex;
            flex-direction: column;

            .results-item {
              position: relative;
              display: flex;
              flex-direction: column;
              gap: 12px;
              width: 100%;
              height: auto;
              border-radius: 8px;

              .info-content {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .edit {
                  svg {
                    fill: rgba(0, 0, 0, 0.4);
                    width: 20px;
                    height: 20px;
                  }
                }
              }

              .info {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-end;
                width: 100%;
                gap: 8px;

                span {
                  color: var(--ion-text-color);
                  line-height: 1.4em;

                  font: {
                    family: $fontBold;
                  }

                  &.info-label {
                    font: {
                      size: 16px;
                    }
                  }

                  &.info-label-small {
                    font: {
                      size: 14px;
                    }
                  }
                }

                .open {
                  position: relative;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  gap: 4px;
                  border: 1px solid rgba(0, 0, 0, 0.4);
                  border-radius: 6px;
                  padding: 0px 6px 0px 6px;

                  ion-icon {
                    color: rgba(0, 0, 0, 0.4);
                    font-size: 18px;
                  }
                }
              }

              .content {
                margin-top: 12px;
                line-height: 1.4;

                font: {
                  family: $fontRegular;
                  size: 16px;
                }
              }

              .slected-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 8px;

                div {
                  position: relative;
                  width: auto;
                  padding: 2px 4px 2px 4px;
                  border: 2px solid transparent;
                  border-radius: 4px;
                  font-size: 0.8em;
                  background-color: var(--ion-color-tertiary);
                  line-height: 1.4;

                  font: {
                    family: $fontRegular;
                    size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  &.public {
    background-color: var(--ion-background-color);
    height: 100%;

    ion-grid {
      max-width: 1248px;
      margin: 0 auto;
      padding: 24px;

      ion-row {
        ion-col {
          &.results {
            position: unset;
            background-color: unset;
            padding: 0;

            h3 {
              margin: 0;
              color: var(--ion-color-dark);

              font: {
                family: $fontMedium;
                size: 20px;
              }
            }

            .results-list {
              .results-item {
                border-radius: 20px;
                grid-column-gap: 16px;
                grid-row-gap: 32px;

                @media (max-width: 767px) {
                  padding: 24px 16px;
                }

                .content {
                  margin: 0;
                  color: var(--ion-color-dark);

                  font: {
                    family: $fontMedium;
                    size: 18px;
                  }
                }

                .info-content {
                  display: block;

                  .info-label {
                    margin-bottom: 8px;
                  }
                }

                .slected-content {
                  gap: 16px;

                  div {
                    font: {
                      family: $fontMedium;
                    }
                  }
                }

                .type-row {
                  ion-col {
                    &:first-child {
                      .info-content {
                        padding-right: 8px;

                        @media (max-width: 767px) {
                          padding: 0;
                          padding-bottom: 24px;
                        }
                      }
                    }

                    &:last-child {
                      .info-content {
                        padding-left: 8px;

                        @media (max-width: 767px) {
                          padding: 0;
                        }
                      }
                    }
                  }
                }

                .type {
                  .info-content {
                    width: 100%;

                    .slected-content {
                      // display: block;
                      border-radius: 6px;
                      width: 100%;
                      padding: 24px;
                      background-color: var(--ion-color-tertiary);

                      div {
                        font: {
                          size: 18px;
                          family: $fontMedium;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.profile-public-top {
            padding: 16px 24px;
            align-items: center;
            border: 1px solid var(--ion-color-light-shade);
            border-radius: 8px;
            background-color: var(--ion-color-tertiary);
            box-shadow: none;
            color: var(--ion-color-dark);

            font: {
              family: $fontMedium;
              size: 16px;
            }

            span {
              color: red;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.counter__section {
  display: flex;
  margin-top: 8px;
  align-items: center;

  ion-input.counter-input {
    margin: 0 -2px;
    width: 48px;
    height: 48px;
    text-align: center;
    --padding-top: 4px;
    --padding-start: 4px;
    --padding-bottom: 4px;
    --padding-end: 4px;
    --border-radius: 0;
  }

  button {
    border: 2px solid var(--ion-input-border-color);
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: var(--ion-color-light);
    color: black;

    font: {
      family: $fontBold;
      size: 16px;
    }

    &:hover {
      border-color: var(--ion-input-hover-border-color);
    }

    &:hover,
    &:focus {
      z-index: 3;
    }

    &.inc {
      border-radius: 0 4px 4px 0;
    }
  }
}


ion-grid {
  &.profile {
    background-color: var(--ion-background-color);

    ion-col {
      &.sidebar {
        background-color: var(--ion-color-tertiary);
        z-index: 100;
        margin-top: -75px;

        .prf-nav-list {
          position: sticky;
          top: 8px;

          .side-logo-div {
            display: flex;
            align-items: center;
            min-height: 44px;
            margin: 0 16px 68px 32px;
          }

          .logo {
            max-height: 56px;
            width: 180px;
            object-fit: unset;
          }
        }

        .list {
          padding: 0 16px 0 32px;
          margin: 0 0 24px 0;

          @media (max-width: 1232px) {
            padding: 0 16px 0 32px;
          }

          h3 {
            margin: 0;

            font: {
              family: $fontBold;
              size: 14px;
            }

            color: var(--ion-color-dark);
            margin: 40px 0 5px 0;
          }

          ion-list {
            padding: 0;
            margin: 0;
            background-color: transparent;

            ion-item {
              --background: transparent;
              cursor: pointer;
              margin-bottom: 4px;
              --min-height: 40px;

              &:hover {
                --background: rgba(0, 0, 0, .1);
                border-radius: 8px;
              }

              i {
                margin-inline-end: 16px;
                margin-top: 0;
                margin-bottom: 0;

                font: {
                  size: 1.5rem;
                  style: unset;
                }
              }

              ion-badge {
                height: 19px;
                min-width: 19px;
                border-radius: 18px;
                font-size: 12px;
                --padding-top: 4px;
                --padding-end: 3px;
                --padding-bottom: 3px;
                --padding-start: 3px;

                font: {
                  family: $fontSemiBold;
                }
              }

              &.active {
                --background: rgba(0, 0, 0, .1);
                border-radius: 8px;

                ion-label {
                  font: {
                    family: $fontMedium;
                  }
                }
              }

              ion-label {
                color: rgba(0, 0, 0, 0.8);

                font: {
                  family: $fontMedium;
                  size: 18px;
                }
              }
            }
          }
        }
      }

      &.sidebar-right {
        height: fit-content;
        position: sticky !important;
        top: 44px !important;
        padding: 109px 32px 48px 0;
        border-radius: 10px;

        &.with-searchbar {
          top: 40px!important;
          padding: 64px 32px 48px 0;

          ion-searchbar {
            min-height: 26px;
            padding: 0;

            input {
              padding-top: 0;
              padding-bottom: 0;
              line-height: 26px;
            }

            .searchbar-search-icon {
              top: 5px;

              &.ios {
                top: 0;
              }
            }
          }
        }

        @media (max-width: 1440px) {
          display: none;
        }
      }

      &.main {
        background-color: var(--ion-background-color);
        padding: 64px 32px 48px 32px;
        min-height: 620px;
        max-width: 840px;

        &.modal {
          padding: 0;
        }

        .map {
          margin-top: 16px;
          height: 370px;
        }

        .map-small {
          .map {
            height: 280px;
          }
        }

        .content-descriptions {
          line-height: 20px;
          max-width: 870px;

          font: {
            family: $fontRegular;
            size: 16px;
          }
        }

        .font-24 {
          font-size: 24px;
          cursor: pointer;
        }

        div {
          &.custom-grid {
            display: grid;
            column-gap: 48px;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

            @media (max-width: 1200px) {
              column-gap: 24px;
            }

            @media (max-width: 480px) {
              grid-template-columns: minmax(0, 1fr);
            }
          }
        }

        h1 {
          color: rgba(0, 0, 0, 0.8) !important;
        }

        h2 {
          color: rgba(0, 0, 0, 0.8) !important;
        }

        @media (max-width: 992px) {
          padding: 24px;
        }

        @media (max-width: 768px) {
          padding: 24px;
          min-height: 240px;
        }

        h1 {
          margin: 0;
        }

        .dark {
          margin-bottom: 10px;
        }

        .select-doc {
          .select-doc-btns {
            background-color: var(--ion-color-light);
            border: 2px solid var(--ion-color-light-shade);
            border-radius: 4px;
            height: auto;

            .select-btn {
              padding: 8px 16px;
              cursor: pointer;
              height: 100%;
              width: 100%;
              text-align: center;

              &.active {
                background-color: var(--ion-color-light-shade)
              }
            }
          }

          img {
            height: 232px;
            margin-top: 16px;
          }
        }

        ion-list {
          &.checkbox-list {
            padding: 0;
            margin: 8px 0 0 0;
            background-color: transparent;
            display: grid;
            width: 70%;
            grid-auto-columns: 1fr;
            gap: 8px;
            grid-template-columns: 1fr 1fr;

            @media (max-width: 767px) {
              width: 100%;
            }

            ion-item {
              --background: transparent;
              cursor: pointer;
              --padding-start: 0;
              --min-height: 30px;
              --background-hover: transparent;

              &:hover {
                background: var(--ion-background-color) !important;
              }

              ion-icon {
                font-size: 24px;
                color: var(--ion-text-color);
                margin-inline-end: 16px;
              }

              ion-label {
                color: var(--ion-text-color);
                margin: 0;
                white-space: normal;

                font: {
                  family: $fontMedium;
                  size: 16px;
                }
              }
            }

            &.new-group-status {
              display: block;
              ion-item {

                ion-label {
                  color: rgba(0, 0, 0, 0.8);;
                  margin: 0;

                  font: {
                    family: $fontMedium;
                    size: 16px;
                  }
                }

                ion-radio {
                  margin: 0 16px  0 0;
                  --color-checked: rgba(0, 0, 0, 0.8);
                  &.checkbox {
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                    border-width: 3px;
                    border-style: solid;
                    border-color: var(--ion-color-dark);
                    checkmark-width: 3;
                    background: var(--ion-item-background, var(--ion-background-color, #fff));
                    --background-checked: var(--ion-color-black);
                    --color-checked: var(--ion-color-white);

                    &::part(mark) {
                      border-width: 0px 3px 3px 0px;
                      border-color: var(--ion-color-light);
                      width: 34%;
                      height: 61%;
                      margin-top: -2px;
                    }

                    &.radio-checked {
                      &::part(container) {
                        background: var(--ion-color-dark);

                      }
                    }


                  }
                }
              }
            }
          }

          &.document-list {
            background-color: transparent;

            ion-item {
              --min-height: 40px;
              --background: transparent;
              --padding-start: 0;
            }
          }
        }

        .select {
          position: relative;
          padding: 24px;
          border: 1px solid transparent;
          border-radius: 8px;
          background-color: var(--ion-color-tertiary);
          cursor: pointer;
          margin-bottom: 16px;

          font: {
            family: $fontRegular;
            size: 1em;
          }

          &:hover {
            border-color: var(--ion-input-border-color);
          }

          &.selected {
            border-color: var(--ion-input-focus-border-color);
          }
        }

        .more-select {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
          padding: 12px;
          background: var(--ion-color-light);
          border: 2px solid var(--ion-color-light-shade);
          border-radius: 4px;

          .select {
            width: auto;
            padding: 6px 8px;
            margin-bottom: 0;
            line-height: 16px;
          }
        }

        .select-item-img-checkbox {
          padding: 12px;
          background: var(--ion-color-light);
          border: 2px solid var(--ion-color-light-shade);

          ion-list {
            &.select-item-img-checkbox-list {
              background-color: var(--ion-color-light);
              padding: 0;
              margin: 0;
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              gap: 8px;

              @media (max-width: 480px) {
                grid-template-columns: unset;
              }


              ion-item {
                --min-height: 40px;
                --background-hover: transparent;
                border: 2px solid transparent;
                border-radius: 4px;

                &:hover {
                  border: 2px solid var(--ion-color-light-shade);
                }

                &.selected {
                  border: 2px solid var(--ion-color-black);
                }

                img {
                  margin-inline-end: 8px;
                  width: 32px;
                  height: 32px;
                  border-radius: 4px;
                  background-size: cover;
                  background-repeat: no-repeat;
                }

                ion-label {
                  font: {
                    family: $fontMedium;
                    size: 1em;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}

ion-grid {
  &.messages {
    padding: 0;

    &.timeline {
      padding: 0;
      background: var(--ion-color-light);
      border-radius: 6px;
      box-shadow: 0 5px 15px rgba(0,0,0,.08);
    }

    &.mobile {
      padding: 16px;
    }

    ion-row {
      &.messages-top {
        border-bottom: 1px solid var(--ion-color-light-shade);
      }
      ion-col {
        padding: 0;

        @media only screen and (max-width: 992px) and (min-width: 768px) {
          &:first-child {
            padding: 0 8px 0 0;
          }

          &:last-child {
            padding: 0 0 0 8px;
          }
        }

        @media only screen and (max-width: 768px) {
          &:first-child {
            padding: 0;
          }

          &:last-child {
            padding: 0;
          }
        }

        .top {
          justify-content: space-between;
          width: 280px;
          height: auto !important;
          padding: 18px 12px 12px 12px;

          span {
            font: {
              family: $fontMedium;
              size: 20px;
            }

            color: var(--ion-color-gray);
            cursor: pointer;

            &.active {
              color: var(--ion-text-color);
              cursor: unset !important;

              font: {
                family: $fontSemiBold;
                size: 20px;
              }
            }

            &:hover {
              color: var(--ion-text-color);
            }
          }

          ion-icon {
            font-size: 20px;
            cursor: pointer;
            color: #2B2B2B;
          }
        }

        &.messages-sidebar {
          border-right: 1px solid var(--ion-color-light-shade);
          .main {
            background: var(--ion-color-light);
            border-radius: 10px;
            height: 500px;
            width: 287px;

            ion-list {
              background: var(--ion-color-light);
              padding: 12px 0;
              border-radius: 10px;

              ion-item {
                --background: var(--ion-color-light);
                --min-height: 64px;
                --padding-end: 12px;
                --padding-start: 12px;
                --inner-padding-end: 12px;
                cursor: pointer;

                &.new {
                  --background: #F2F2F2;
                }

                h4 {
                  font: {
                    family: $fontSemiBold;
                    size: 14px;
                  }
                }

                p {
                  font: {
                    family: $fontSemiBold;
                    size: 14px;
                  }

                  color: var(--ion-color-gray) !important;
                }

                &:last-child {
                  --border-width: 0 !important;
                  --border-color: transparent !important;
                }

                ion-avatar {
                  width: 40px;
                  height: 40px;
                }

                ion-badge {
                  height: 18px;
                  width: 18px;
                  border-radius: 18px;
                  font-size: 12px;
                  --padding-top: 3px;
                  --padding-end: 3px;
                  --padding-bottom: 3px;
                  --padding-start: 3px;

                  font: {
                    family: $fontSemiBold;
                  }
                }

                .date {
                  position: absolute;
                  right: 2px;
                  top: 18px;

                  font: {
                    family: $fontSemiBold;
                    size: 12px;
                  }

                  color: var(--ion-color-gray);
                }

                @media only screen and (max-width: 1100px) and (min-width: 992px) {
                  ion-avatar {
                    width: 34px;
                    height: 34px;
                    margin-right: 8px;
                  }

                  ion-label {
                    h1 {
                      font: {
                        family: $fontBold;
                        size: 12px;
                      }

                      margin-bottom: 5px;
                    }

                    p {
                      font: {
                        size: 10px;
                      }

                      color: #585858;
                      margin: 0;
                      line-height: 14px;
                    }
                  }
                }
              }
            }
          }
        }

        &.messages-main {
          min-width: 0;

          .main {
            background: var(--ion-color-light);
            border-radius: 10px;
            padding: 12px;
            height: 460px;

            .date {
              font: {
                family: $fontSemiBold;
                size: 12px;
              }

              color: #585858;
              padding-bottom: 5px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            }

            .item {
              position: relative;
              padding: 16px 0;

              ion-avatar {
                width: 34px;
                height: 34px;
                margin-right: 12px;
              }

              .message-content {
                font: {
                  family: $fontRegular;
                  size: 14px;
                }

                line-height: 18px;
                white-space: pre-line;
                width: 100%;
                min-width: 0;

                h4 {
                  font: {
                    family: $fontBold;
                    size: 14px;
                  }

                  margin: 5px 0 12px 0;
                }
              }

              .time {
                position: absolute;
                right: 0;
                top: 18px;

                font: {
                  size: 12px;
                }

                color: #585858;
              }


            }

            &.mobile {
              background: unset;
              border: none;
              box-shadow: none;
              border-radius: none;
              padding: 0;
              min-height: unset;

              @media only screen and (max-width: 768px) {
                min-height: unset;
              }

              .main-item {
                margin: 24px 0;

                &:first-child {
                  margin: 12px 0 24px 0;
                }
              }

              .date {
                font: {
                  family: $fontSemiBold;
                  size: 14px !important;
                }

                color: #585858;
                max-width: fit-content;
                margin: 0 auto;
                text-align: center;
                padding-bottom: 3px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              }

              .item {
                &.guest {
                  background: rgba(0, 0, 0, 0.05);
                  border: 1px solid rgba(0, 0, 0, 0.15);
                  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
                  padding: 12px;
                  border-radius: 8px;
                  margin: 12px 15vw 8px 0;

                  .message-content {
                    margin-bottom: 20px;
                  }

                  .time {
                    position: absolute;
                    right: 12px;
                    top: auto !important;
                    bottom: 8px !important;

                    font: {
                      size: 12px;
                    }

                    color: #585858;
                  }
                }

                &.host {
                  background: var(--ion-color-tertiary);
                  border: 1px solid rgba(0, 0, 0, 0.15);
                  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
                  padding: 12px;
                  border-radius: 8px;
                  margin: 12px 0 8px 15vw;

                  .message-content {
                    margin-bottom: 20px;
                  }

                  .time {
                    position: absolute;
                    right: 12px;
                    top: auto !important;
                    bottom: 8px !important;

                    font: {
                      size: 12px;
                    }

                    color: #585858;
                  }
                }
              }
            }
          }

          .post-write {
            border: 0.5px solid rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            background: var(--ion-color-light);
            margin-bottom: 0 !important;
            padding: 18px 12px !important;

            ion-grid {
              padding: 0;

              ion-row {
                padding: 0;

                ion-col {
                  padding: 0;

                  &:first-child {
                    padding-right: 12px;
                  }

                  &:last-child {
                    padding-left: 12px;
                  }
                }
              }
            }

            ion-avatar {
              width: 34px;
              height: 34px;
            }

            @media screen and (max-width: 768px) {
              ion-icon {
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}

ion-footer {
  .post-write {
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    border-width: 0.5px 0 0 0;
    border-radius: 10px;
    background: var(--ion-color-light);
    margin-bottom: 0 !important;
    padding: 20px 12px 24px 12px !important;

    ion-grid {
      padding: 0;

      ion-row {
        padding: 0;

        ion-col {
          padding: 0;

          &:first-child {
            padding-right: 12px;
          }

          &:last-child {
            padding-left: 12px;
          }
        }
      }
    }

    ion-avatar {
      width: 34px;
      height: 34px;
    }

    @media screen and (max-width: 768px) {
      ion-icon {
        padding-bottom: 5px;
      }
    }
  }
}

ion-list {
  &.chats-list {
    height: 500px;
    overflow: auto;
  }
}

.account-profile-card {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 10px;
  min-width: 360px;
  background: var(--ion-color-light);
  position: relative;

  ion-thumbnail {
    width: 100%;
    height: 120px;
  }

  ion-avatar {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  h2 {
    font-size: 24px;
  }

  p {
    margin: 0;
    font: {
      size: 14px;
      family: $fontSemiBold;
    }

    color: var(--ion-color-gray);
  }

  .title-with-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 16px;

    h2 {
      margin: 0;
    }

    ion-button {
      --padding-top: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-end: 0;

      ion-icon {
        font-size: 24px;
      }
    }
  }

  .list-count {
    margin-top: 16px;
    border-top: 1px solid var(--ion-color-light-shade);
    padding-top: 16px;

    ion-item {
      --background: var(--ion-color-light);
      --min-height: 28px;
      --padding-start: 0px;
      --inner-padding-end: 0;

      ion-label {
        margin: 0;
        color: var(--ion-color-gray);

        font: {
          size: 18px;
          family: $fontSemiBold;
        }
      }

      p {
        font: {
          size: 18px;
          family: $fontSemiBold;
        }

        color: var(--ion-color-primary-shade);
      }
    }
  }
}

.nav-link {
  display: flex;
  flex: 1;
  align-items: center;
  text-decoration: none;
}
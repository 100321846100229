$font-path: "../assets/fonts/" !default;

// Weights
$hairline-weight: 100;
$thin-weight:     200;
$light-weight:    300;
$normal-weight:   400;
$medium-weight:   500;
$semibold-weight: 600;
$bold-weight:     700;
$xbold-weight:    800;
$black-weight:    900;

$fontRegular: "Inter-Regular";
$fontMedium: "Inter-Medium";
$fontBold: "Inter-Bold";
$fontSemiBold: "Inter-SemiBold";
$fontIcon: "icons";

@font-face {
  font: {
    family: $fontRegular;
    weight: $normal-weight;
    style: normal;
  }

  src: url("#{$font-path}/#{$fontRegular}.eot");
  src:
    url("#{$font-path}/#{$fontRegular}.eot") format("embedded-opentype"),
    url("#{$font-path}/#{$fontRegular}.woff2") format("woff2"),
    url("#{$font-path}/#{$fontRegular}.woff") format("woff"),
    url("#{$font-path}/#{$fontRegular}.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font: {
    family: $fontMedium;
    weight: $medium-weight;
    style: normal;
  }

  src: url("#{$font-path}/#{$fontMedium}.eot");
  src:
    url("#{$font-path}/#{$fontMedium}.eot") format("embedded-opentype"),
    url("#{$font-path}/#{$fontMedium}.woff2") format("woff2"),
    url("#{$font-path}/#{$fontMedium}.woff") format("woff"),
    url("#{$font-path}/#{$fontMedium}.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font: {
    family: $fontSemiBold;
    weight: $semibold-weight;
    style: normal;
  }
  
  src: url("#{$font-path}/#{$fontSemiBold}.eot");
  src:
    url("#{$font-path}/#{$fontSemiBold}.eot") format("embedded-opentype"),
    url("#{$font-path}/#{$fontSemiBold}.woff2") format("woff2"),
    url("#{$font-path}/#{$fontSemiBold}.woff") format("woff"),
    url("#{$font-path}/#{$fontSemiBold}.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font: {
    family: $fontBold;
    weight: $bold-weight;
    style: normal;
  }
  
  src: url("#{$font-path}/#{$fontBold}.eot");
  src:
    url("#{$font-path}/#{$fontBold}.eot") format("embedded-opentype"),
    url("#{$font-path}/#{$fontBold}.woff2") format("woff2"),
    url("#{$font-path}/#{$fontBold}.woff") format("woff"),
    url("#{$font-path}/#{$fontBold}.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font: {
    family: $fontIcon;
    weight: $normal-weight;
    style: normal;
  }

  src: url("#{$font-path}/#{$fontIcon}.eot");
  src:
    url("#{$font-path}/#{$fontIcon}.eot") format("embedded-opentype"),
    url("#{$font-path}/#{$fontIcon}.woff2") format("woff2"),
    url("#{$font-path}/#{$fontIcon}.woff") format("woff"),
    url("#{$font-path}/#{$fontIcon}.ttf") format("truetype");
  font-display: swap;
}

.icon::before,
.icon::after {
  font: {
    family: $fontIcon;
    weight: normal;
    style: normal;
  }

  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-align::before,
.icon-align::after {
  vertical-align: middle;
}

$icons: (
  Frame: 'ea01',
  accept-user: 'ea02',
  alarm: 'ea03',
  alert-circle: 'ea04',
  alert-triangle: 'ea05',
  at-sign: 'ea06',
  award: 'ea07',
  bell: 'ea08',
  box: 'ea09',
  clock: 'ea0a',
  compass: 'ea0b',
  dot: 'ea0c',
  euro: 'ea0d',
  facebook: 'ea0e',
  faq: 'ea0f',
  feed: 'ea10',
  file-text: 'ea11',
  global: 'ea12',
  grid: 'ea13',
  group: 'ea14',
  groups: 'ea15',
  hash: 'ea16',
  home: 'ea17',
  image: 'ea18',
  info: 'ea19',
  instagram: 'ea1a',
  key: 'ea1b',
  link: 'ea1c',
  linkedin: 'ea1d',
  location: 'ea1e',
  map: 'ea1f',
  megaphone: 'ea20',
  menu: 'ea21',
  message: 'ea22',
  profile: 'ea23',
  pusula: 'ea24',
  rocket: 'ea25',
  send: 'ea26',
  settings: 'ea27',
  sliders: 'ea28',
  smile: 'ea29',
  spanner: 'ea2a',
  star: 'ea2b',
  tag: 'ea2c',
  twitter: 'ea2d',
  user: 'ea2e',
);

@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

@each $name, $code in $icons {
  .icon-#{"" + $name}::before {
    content: unicode($code);
  }
}

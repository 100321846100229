.login-btn {
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;
  color: var(--ion-color-light);
  background: var(--ion-color-primary);

  font: {
    family: $fontBold;
    size: $font20;
  };

  &:hover {
    background: var(--ion-color-primary-shade);
  }
}

.btn {
  padding: 6px 30px;
  border-radius: 4px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontBold;
    size: 16px;
  };

  &:hover {
    background-color: var(--ion-color-black);
  }
}

.btn-next {
  padding: 6px 12px;
  border-radius: 4px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  text-align: center;
  line-height: 20px;
  min-width: 80px;

  font: {
    family: $fontBold;
    size: $font16;
  };

  &:hover {
    background-color: var(--ion-color-black);
  }

  &.save {
    background: var(--ion-color-primary);

    &:hover {
      background: var(--ion-color-primary-shade);
    }
  }
}

.btn-save {
  padding: 8px 24px;
  border-radius: 4px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  text-align: center;
  line-height: 20px;
  min-width: 80px;

  font: {
    family: $fontBold;
    size: $font16;
  };

  &:hover {
    background-color: var(--ion-color-black);
  }

  @media (max-width: 480px) {
    padding: 8px 24px;
    width: 100%;
  }
}

.btn-voor {
  padding: 6px 12px;
  border-radius: 4px;
  min-width: 85px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontBold;
    size: $font16;
  };

  &:hover {
    color: var(--ion-text-color);
  }
}

.btn-upload {
  padding: 6px 12px;
  border: 2px solid var(--ion-color-black);
  border-radius: 4px;
  min-width: 85px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontMedium;
    size: $font16;
  };

  &:hover {
    background-color: var(--ion-color-black);
    color: var(--ion-color-light);
  }
}

.btn-delete {
  padding: 6px 12px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 4px;
  min-width: 85px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-primary);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontMedium;
    size: $font16;
  };

  &:hover {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
  }
}

.btn-follow {
  padding: 16px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 4px;
  width: 100%;
  background-color: var(--ion-color-light);
  color: var(--ion-color-primary);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontMedium;
    size: $font16;
  };

  &:hover {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
  }

  &.unfollow {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);

    &:hover {
      background-color: var(--ion-color-light);
      color: var(--ion-color-primary);
    }
  }
}


.btn-popover {
  padding: 6px 12px 6px 12px;
  border: 2px solid var(--ion-color-primary);
  line-height: 20px;
  border-radius: 4px;
  color: var(--ion-color-primary);
  background-color: transparent;
  font: {
    family: $fontMedium;
    size: $font16;
  };
}

.btn-login {
  padding: 8px 12px;
  border: 2px solid var(--ion-color-light);
  border-radius: 4px;
  min-width: 85px;
  background-color: var(--ion-color-light);
  color: var(--ion-text-color);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontMedium;
    size: $font16;
  }

  &:hover {
    border: 2px solid var(--ion-color-light-shade);
    background-color: var(--ion-color-light-shade);
  }
}

.btn-update {
  display: inline-block;
  padding: 8px 12px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 4px;
  min-width: 88px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontMedium;
    size: $font16;
  };

  &.dashboard {
    padding: 9px 12px;
    display: block;
    width: max-content;
  }

  &.followed {
    background-color: var(--ion-color-light);
    color: var(--ion-color-primary);
    padding-right: 18px;
  }

  &:hover {
    border: 2px solid var(--ion-color-primary-shade);
    background-color: var(--ion-color-primary-shade);
    color: var(--ion-color-light);
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      border: 2px solid var(--ion-color-primary);
      background-color: var(--ion-color-primary);
    }
  }

  &.share {
    background-color: transparent;
    color: var(--ion-color-primary);
    border: 2px solid transparent;

    &:hover {
      border: 2px solid transparent;
      background-color: transparent;
      color: var(--ion-color-primary);
    }
  }

  &.btn-big {
    @media (max-width: 768px) {
      padding: 16px 32px;
    }

    @media (min-width: 768px) {
      padding: 16px 48px;
      font-size: 1.2rem;
    }
  }

  ion-icon {
    font-size: 22px;
    vertical-align: text-top;
  }

  &.secondary {
    background-color: var(--ion-color-secondary);
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 3;
    min-width: 200px;
    display: block !important;
  }
}

.btn-update-big {
  padding: 12px 16px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 4px;
  min-width: 85px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontMedium;
    size: $font16;
  };

  &:hover {
    border: 2px solid var(--ion-color-primary-shade);
    background-color: var(--ion-color-primary-shade);
    color: var(--ion-color-light);
  }
}

.btn-filter {
  padding: 8px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  min-width: 85px;
  background-color: var(--ion-color-tertiary);
  color: var(--ion-text-color);
  text-align: center;
  line-height: 20px;
  white-space: nowrap;

  font: {
    family: $fontMedium;
    size: $font16;
  };

  &:hover {
    border: 1px solid var(--ion-color-medium);
  }
}

.btn-transparant {
  padding: 12px 16px;
  border: none;
  background-color: transparent;
  color: var(--ion-text-color);
  text-align: center;
  line-height: 20px;

  font: {
    family: $fontMedium;
    size: $font24;
  };
}

a {
  &.inline-block {
    display: inline-block;
  }

  &.btn {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }


  &.btn-update-big {
    text-decoration: none;
    display: inline-block;
  }

  &.btn-filter {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }

  &.btn-update {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }

  &.btn-login {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }

  &.btn-popover {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }

  &.btn-follow {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }

  &.btn-delete {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }
  &.btn-upload {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }
  &.btn-save {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }
  &.btn-next {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }
  
  &.login-btn {
    text-decoration: none;
    @media (max-width: 480px) {
      display: block;
    }
  }
}

.modal-close-button {
  --color: var(--ion-text-color);
  --color-hover: var(--ion-color-primary);
  --padding-start: 0!important;
  --padding-end: 0!important;
}

.map-filters-button {
  position: absolute;
  z-index: 2;
}

ion-button {
  text-transform: none;
  letter-spacing: normal;

  --box-shadow: none;
  --padding-top: 0;
  --padding-bottom: 0;

  font: {
    family: $fontMedium;
  }

  &.send {
    ion-icon {
      transform: rotate(-45deg);
    }
  }

  &.auth-provider-btn {
    height: 40px;

    img {
      width: 1.3em;
      height: 1.3em;
      margin-right: 8px;
    }
  }
}

button {
  &.link {
    color: var(--ion-color-primary);
    text-decoration: underline;
    background: none;

    font: {
      family: $fontRegular;
      size: 1rem;
    }
  }
}

.read-more-button {
  border-bottom: 2px solid var(--ion-color-primary);
  cursor: pointer;
  padding-bottom: 5px;
  color: var(--ion-color-primary);

  font: {
    family: $fontMedium;
  }

  ion-icon {
    font-size: 24px;
  }
}

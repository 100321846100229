ion-footer {
  &.mobile {
    background: var(--ion-color-light);
    ion-grid {
      padding: 0;
      box-shadow: 0 -1px 2px 0 rgb(0 0 0 / 6%), 0 -1px 3px 0 rgb(0 0 0 / 10%);
      ion-row {
        padding: 11px;
      }
    }
    ion-icon {
      &.compass {
        font-size: 24px;
      }

      &.up {
        color: var(--ion-text-color);
        font-size: 20px;
        vertical-align: sub;
      }

      &.icon-alarm {
        font-size: 24px;
      }
    }

    ion-badge {
      position: absolute;
      top: -6px;
      right: -4px;
      font: {
        family: $fontSemiBold;
      }
    }

    .label {
      color: var(--ion-text-color);

      font: {
        family: $fontBold;
        size: 14px;
      }
    }

    ion-avatar {
      width: 22px;
      height: 22px;
    }

    .name {
      font: {
        family: $fontBold;
        size: 16px;
      }

      color: var(--ion-text-color);
      padding: 0 8px;
    }
  }

  &.desktop {
    background: var(--ion-background-color);
    &::before {
      height: 0;
    }

    ion-grid {
      &.footer {
        padding: 0 24px 24px 24px;

        ion-row {
          ion-col {
            img {
              width: 180px;
              height: auto;
            }

            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }

        @media (max-width: 768px) {
          ion-col {
            padding: 0;
          }
        }

        &.border {
          border-top: 1px solid var(--ion-color-light-shade);
          border-bottom: 1px solid var(--ion-color-light-shade);
          padding: 30px 0;

          @media (max-width: 480px) {
            border-bottom: 2px solid transparent;
          }
        }

        &.copyright {
          padding: 30px 0 10px 0;

          .copyright-terms-and-privacy {
            @media (max-width: 992px) {
              order: 2;
              margin-top: 16px;
              text-align: center;
            }
          }

          .terms {
            font: {
              family: $fontRegular;
              size: 14px;
            }

            color: black;
            text-decoration: none;

            span,
            a {
              cursor: pointer;

              font: {
                family: $fontRegular;
                size: 14px;
              }

              margin-left: 16px;

              color: black;
              text-decoration: solid underline black 1px;
            }
          }

          .icons {
            display: flex;
            gap: 12px;
            justify-content: right;

            a {
              -webkit-transition:  -webkit-transform .2s ease-out;
              -moz-transition: -moz-transform .2s ease-out;
              -o-transition: -o-transform .2s ease-out;
              -ms-transition: -ms-transform .2s ease-out;
              transition: transform .2s ease-out;

              font: {
                size: 1.75rem;
              }

              &:hover {
                -webkit-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -o-transform: scale(1.2);
                transform: scale(1.2);
              }
            }

            @media (max-width: 992px) {
              justify-content: center;
            }
          }
        }
        }

        .content {
          color: #606862;
          font: {
            family: $fontMedium;
            size: 16px;
          }

          line-height: 1.4em;
        }

        .link {
          color: #606862;
          font: {
            family: $fontMedium;
            size: 16px;
          }

          line-height: 1.4em;
          cursor: pointer;
          text-decoration: none;
          display: block;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            text-decoration: underline;
          }
        }

        h5 {
          color: var(--color);
          margin-top: 6px;

          font: {
            family: $fontBold;
            size: 1.125em;
          }

          line-height: 1.4em;
        }

        .links-container {
          display: grid;
          grid-gap: 16px;
          grid-template-columns: repeat(3, 1fr);

          @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.popover-footer {
  --offset-y: -10px;

  .link {
    font: {
      family: $fontMedium;
      size: 14px;
    }

    line-height: 1.5em;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      color: var(--ion-color-primary);
    }
  }
} 

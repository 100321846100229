.auth {
  padding: 0;
  .logo {
    width: 240px;
    position: absolute;
    top: 20px;
    z-index: 2;
    left: 50px;

    img {
      cursor: pointer;
    }
  }

  ion-row {
    &.logo-row {
      justify-content: center;
      @media (max-width: 767px) {
        padding-top: 40px;
      }
      .logo2 {
        padding-top: 20px;
        width: 180px;
      }
    }
  }

  .auth-row {
    height: 100vh;
    .auth-background {
      background-color: var(--ion-color-tertiary);
      height: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .login-content {
    --color: var(--ion-color-dark);
    min-height: 100%;

    @media (max-width: 767px) {
      min-height: 82%;
    }

    .wrapper {
      width: 100%;
      p {
        font: {
          size: 16px;
        };
      }
      .form-container {

        ion-input {
          margin: 8px 0;
        }

        ion-row {
          justify-content: center;

          ion-col {
            ion-input {
              margin: 0;
            }
          }
        }

        .wants-newsletter-item {
          ion-label {
            font: {
              family: $fontRegular;
              size: 14px;
            }
          }
        }
      }

      .auth-modal-form {
        padding: 0 80px 56px 80px;

        @media (max-width: 767px) {
          padding: 0 24px;
        }
      }
    }

    .btn-row {
      justify-content: center;
    }

  }

  .password-forgot {
    justify-content: center;
    p {
      font: {
        family: $fontRegular;
        size: 16px;
      };

      span {
        color: var(--ion-color-primary);
        cursor: pointer;
        text-decoration: underline;

        font: {
          family: $fontBold;
        };

        &:hover {
          color: var(--ion-color-primary-shade);
        }

      }
    }
  }

  ion-item {
    --background: transparent;
  }
}


ion-toast {
  --white-space: pre-wrap;
}

ion-input {
  &.sc-ion-input-md-s.input-fill-outline,
  &.sc-ion-input-md-h.input-fill-outline,
  &.sc-ion-input-md-s,
  &.sc-ion-input-md-h {
    width: 100%;
    min-height: 42px;

    --color: var(--ion-text-color);
    --background: var(--ion-color-light);
    --highlight-color: var(--ion-input-hover-border-color);
    --highlight-color-focused: var(--ion-input-focus-border-color);
    --highlight-color-valid: var(--ion-input-border-color);
    --highlight-color-invalid: var(--ion-input-border-color);
    --border-color: var(--ion-input-border-color);
    --border-width: 2px;
    --border-radius: 4px;

    --padding-top: 8px;
    --padding-start: 12px;
    --padding-bottom: 8px;
    --padding-end: 12px;

    font: {
      family: $fontMedium;
      size: 1rem;
    }

    @media (any-hover: hover) {
      &:hover {
        --border-color: var(--ion-input-hover-border-color);
      }
    }

    &.has-focus {
      &.ion-valid,
      &.ion-invalid {
        --border-color: var(--ion-input-focus-border-color);
      }
    }

    &[labelplacement=top] {
      --background: none;
      --padding-top: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-end: 0;

      input.native-input {
        background: var(--ion-color-light);
        border: 2px solid var(--ion-input-border-color);
        border-radius: 4px;
        padding: 10px 12px;
      }

      .input-outline-container {
        display: none;
      }

      @media (any-hover: hover) {
        &:hover {
          input.native-input {
            border-color: var(--ion-input-hover-border-color);
          }
        }
      }

      &.has-focus {
        input.native-input {
          border-color: var(--ion-input-focus-border-color);
        }
      }
    }
  }

  &[labelplacement=top] {
    label {
      gap: 8px;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  .label-text-wrapper.sc-ion-input-md {
    max-width: unset;
    color: var(--ion-text-color);
    font: {
      family: $fontBold;
      size: 1rem;
    }
  }

  &.label-color-medium {
    .label-text-wrapper.sc-ion-input-md {
      color: var(--ion-color-medium);
    }
  }

  &.disabled {
    background: var(--ion-color-light-shade);
  }

  &.number {
    height: 30px;
    @media (min-width: 991px) and (max-width: 1400px) {
      max-width: 400px;
    }
  }

  .input-highlight {
    display: none;
  }
}

ion-textarea {
  &.sc-ion-textarea-md-s.textarea-fill-outline,
  &.sc-ion-textarea-md-h.textarea-fill-outline,
  &.sc-ion-textarea-md-s,
  &.sc-ion-textarea-md-h {
    margin: 0;
    width: 100%;
    min-height: 128px;

    --background: var(--ion-color-light);
    --highlight-color: var(--ion-input-hover-border-color);
    --highlight-color-focused: var(--ion-input-focus-border-color);
    --highlight-color-valid: var(--ion-input-border-color);
    --highlight-color-invalid: var(--ion-input-border-color);
    --border-color: var(--ion-input-border-color);
    --border-width: 2px;
    --border-radius: 4px;

    --padding-top: 12px;
    --padding-bottom: 12px;
    // The padding start is needed to make the left side have a border radius of 4px
    --padding-start: 12px;
    // Padding end is set on the textarea itself, otherwise the scrollbar also has padding
    --padding-end: 0;

    font: {
      family: $fontMedium;
      size: 1rem;
    }

    @media (any-hover: hover) {
      &:hover {
        &.ion-valid,
        &.ion-invalid {
          --border-color: var(--ion-input-hover-border-color);
        }
      }
    }

    &.has-focus {
      &.ion-valid,
      &.ion-invalid {
        --border-color: var(--ion-input-focus-border-color);
      }
    }

    textarea.native-textarea {
      -webkit-padding-start: 0;
      padding-inline-start: 0;
      -webkit-padding-end: 12px;
      padding-inline-end: 12px;
    }

    &[labelplacement=top] {
      --background: none;
      --padding-top: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-end: 0;

      textarea.native-textarea {
        background: var(--ion-color-light);
        border: 2px solid var(--ion-input-border-color);
        border-radius: 4px;
        padding: 10px 12px;
      }

      .textarea-outline-container {
        display: none;
      }

      @media (any-hover: hover) {
        &:hover {
          textarea.native-textarea {
            border-color: var(--ion-input-hover-border-color);
          }
        }
      }

      &.has-focus {
        textarea.native-textarea {
          border-color: var(--ion-input-focus-border-color);
        }
      }
    }

    &[auto-grow] {
      min-height: 0;

      .native-wrapper {
        min-height: 0;
      }

      &.with-max-height {
        .native-wrapper {
          max-height: 160px;
        }

        textarea {
          overflow: auto;
        }
      }
    }

    .end-slot-wrapper {
      -ms-flex-item-align: end;
      align-self: end;

      ion-button {
        margin: 0 4px;
      }
    }
  }

  &[labelplacement=top] {
    label {
      gap: 8px;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  .label-text-wrapper.sc-ion-textarea-md {
    max-width: unset;
    color: var(--ion-text-color);
    font: {
      family: $fontBold;
      size: 1rem;
    }
  }

  &.label-color-medium {
    .label-text-wrapper.sc-ion-textarea-md {
      color: var(--ion-color-medium);
    }
  }

  &.disabled {
    background: var(--ion-color-light-shade);
  }
}

ion-select {
  min-height: 42px;

  --background: var(--ion-color-light);
  --border-width: 2px;
  --border-radius: 4px;
  --border-color: var(--ion-input-border-color);
  --highlight-color: none;
  --padding-start: 16px;
  --padding-end: 16px;

  font: {
    family: var(--ion-font-family-medium);
  }

  .label-text, [slot=label] {
    white-space: normal;
  }

  .label-with-icons {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;

    ion-icon {
      font: {
        size: 21px;
      }
    }
  }

  @media (any-hover: hover) {
    &:hover {
      --border-color: var(--ion-input-hover-border-color);
    }
  }

  &.select-expanded {
    --border-color: var(--ion-input-focus-border-color);
  }

  &.ion-focused {
    --border-color: var(--ion-input-focus-border-color);
  }

  &::part(label) {
    max-width: unset;
    margin: 0;
  }

  &::part(icon) {
    transform: none;
    width: 1em;
    color: inherit;

    font: {
      size: 22px;
    }
  }

  &:not(&.show-selected) {
    &::part(text) {
      display: none;
    }
  }

  &[toggleIcon=""] {
    &::part(icon) {
      display: none;
    }
  }

  &[labelplacement=center] {
    &::part(label) {
      justify-content: center;
      width: 100%;
    }
  }
}

ion-toggle {
  --track-background: #E5E5E5;
  --handle-spacing: 3px;
  --handle-background: var(--ion-color-medium) url(/assets/images/min.svg) no-repeat center / contain;
  --handle-color: var(--ion-color-light);
  --handle-width: 1.5rem;
  --handle-height: 1.5rem;
  overflow: visible;
  contain: none;

  &::part(track) {
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    width: 3.125rem;
    height: 1.75rem;
  }

  &::part(handle) {
    color: var(--ion-color-light);
  }

  &::part(label) {
    color: var(--ion-color-medium);

    font: {
      family: var(--ion-font-family-bold);
      size: 1rem;
    }
  }

  &.member {
    --handle-background-checked: var(--ion-color-success-shade) url(/assets/images/check.svg) no-repeat center / contain;
    --track-background-checked: #D1F4DF;
  }

  &.process {
    --track-background-checked: rgba(255, 107, 0, 0.26);
    --handle-background-checked: rgba(255, 107, 0, 0.82) url(/assets/images/clock.svg) no-repeat center / contain;
  }
}

ion-content {
  --background: var(--ion-background-color) !important;

  &.faq {
    ion-grid {
      &.faq-grid {
        width: 100%;
        padding: 0 24px;
        margin: 40px 0;

        @media (max-width: 767px) {
          ion-row {
            padding: 0;

            ion-col {
              padding: 0 !important;
            }
          }
        }

        &.light {
          background: var(--ion-background-color);
        }

        &.dark {
          background: var(--ion-color-tertiary);

          ion-row {
            &:last-child {
              padding-top: 40px;

              @media (max-width: 767px) {
                padding-top: 0;
              }
            }

            &.no-padding {
              padding: 0 !important;
            }
          }
        }

        ion-row {
          max-width: 940px;
          margin: 0 auto;

          &.max-w-1200 {
            max-width: 1200px;
          }

          &.no-padding {
            padding: 0 !important;
          }

          &.contact {
            @media (max-width: 960px) {
              ion-col {
                &:last-child {
                  padding-left: 0 !important;
                }
              }
            }

            @media (max-width: 767px) {
              ion-col {
                margin-bottom: 16px;
              }
            }
          }

          &.help-improve-platform {
            max-width: 1200px;
          }

          @media (max-width: 767px) {
            flex-wrap: wrap;
          }

          .subtitle {
            color: var(--ion-text-color);
            font-size: 1.25rem;
          }

          &.faq-list {
            max-width: 680px;
            padding-bottom: 48px;

            ion-col {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }

          ion-col {
            &:first-child {
              padding-right: 20px;
            }

            &:last-child {
              padding-left: 20px;
            }

            &.ion-hide-md-down {
              img {
                height: 40vh;
              }
            }

            @media (max-width: 480px) {
              padding: 0 !important;

              &.order {
                order: 2;
              }
            }
          }

          &.auth-row {
            height: auto !important;

            ion-col {
              margin-bottom: 10px;

              &:first-child {
                padding-right: 5px;
              }

              &:last-child {
                padding-left: 5px;
              }
            }

            textarea {
              min-height: 168px;
              margin-top: 8px;
              border: 2px solid var(--ion-color-light-shade);
              border-radius: 4px;
              font-size: 16px;
              background-color: var(--ion-color-light);

              &:focus {
                border: 2px solid var(--ion-color-black) !important;
              }
            }
          }
        }
      }
    }
  }

  &.discover {
    --background: var(--ion-background-color);
  }

  &.home {
    --background: var(--ion-background-color);

    .top-logo {
      .logo {
        max-width: 848px;
        padding: 12px 24px;
        margin: 0 auto;

        img {
          width: 200px;
        }
      }
    }

    .top {
      .content-top {
        max-width: 848px;
        margin: 0 auto;
        height: 100%;
        position: relative;
        padding: 24px 24px 55px 24px;

        @media (max-width: 768px) {
          padding: 24px 24px 32px 24px;
        }

        .header-content-project {
          max-width: 540px;
          z-index: 1;
          // bottom: 20px;
          padding: 0;

          h1 {
            text-shadow: 0 0 3px var(--ion-color-medium);
            color: var(--ion-color-light);
            font-size: 68px;
            line-height: 67px;
            margin: 0;

            @media (max-width: 768px) {
              font-size: 43px !important;
              line-height: 43px !important;
            }
          }

          .subtitle {
            text-shadow: 0 1px 6px var(--ion-color-black);
            color: var(--ion-color-light);
          }
        }
      }

    }

    .banner-img {
      border: 0;
      width: 100%;
      height: unset;
      vertical-align: middle;
      display: inline-block;
    }

    .subtitle {
      color: var(--ion-text-color);
    }

    .text-with-button-container {
      text-align: center;
      padding: 80px 0 16px 0;
      max-width: 660px;
      margin: 0 auto;

      .subtitle {
        color: var(--ion-text-color);
      }

      h1 {
        &.big {
          font-size: 4rem;
        }
      }

      @media (max-width: 768px) {
        padding: 30px 24px;
      }
    }

    ion-grid {
      &.dark {
        background: var(--ion-color-tertiary);
        padding: 40px 0;

        ion-row {
          padding: 0;
          max-width: 940px;
          margin: 0 auto;

          ion-col {
            padding: 0;

            &:first-child {
              padding-right: 32px;
            }

            &:last-child {
              padding-left: 32px;

              .slide-title {
                padding-bottom: 20px;
              }
            }

            @media (max-width: 940px) {
              padding: 16px 24px !important;
            }

            @media (max-width: 480px) {
              &:last-child {
                .slide-title {
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
      }

      &.light {
        background: var(--ion-background-color);
        max-width: 1248px;
        margin: 0 auto;
        padding: 0;

        .w-660 {
          max-width: 660px !important;
          margin: 0 auto;
        }

        &.margin-vertical-40 {
          margin: 40px auto;
        }

        &.card-loop {
          @media (max-width: 1200px) {
            padding: 0 16px;
          }

          @media (max-width: 767px) {
            padding: 16px;
          }

          ion-row {
            ion-col {
              padding: 16px;
              border-radius: 4px;

              &.title {
                padding-bottom: 0;
                padding-top: 0;

                h4,
                h3 {
                  margin: 0;
                }
              }

              &.link {
                padding-bottom: 0;
                padding-top: 0;

                .link {
                  text-align: right;
                  margin: 0;
                }
              }

              @media (max-width: 767px) {
                padding: 8px;

                &.link {
                  padding-bottom: 0;

                  .link {
                    text-align: left;
                    margin-top: 8px;
                  }
                }

                &.title {
                  padding-bottom: 0;
                }
              }

              @media (max-width: 480px) {
                ion-card {
                  &.card-group {
                    margin: 8px;
                  }
                }
              }

            }

            @media (max-width: 1216px) {
              padding-left: 0;
              padding-right: 0;

              ion-card {
                &.card-group {
                  margin: 8px;
                }
              }
            }
          }
        }

        ion-row {
          padding: 8px 8px;

          ion-col {
            padding: 8px;
            border-radius: 4px;

            &.first {
              padding: 16px;
              padding-right: 24px;
            }

            &.no-padding {
              padding: 0;
            }

            &.search-bar {
              padding: 8px 0;
            }

            &.search-text {
              padding: 0;

              p {
                margin: 0;
                padding-left: 16px;
              }
            }
          }

          &.loop {
            padding: 10px 24px 0 24px;

            ion-col {
              padding: 0;
            }
          }

          &.side {
            ion-col {
              height: 20vw;

              @media (max-width: 767px) {
                height: 30vw;
              }

              @media (max-width: 480px) {
                height: 35vw;
              }

              &:first-child {
                padding-right: 16px;
              }

              &:last-child {
                padding-left: 16px;
                padding-right: 16px;
              }
            }
          }

          &.side-mobile {
            padding-right: 8px;
          }

          @media (max-width: 1200px) {
            padding-left: 16px;
            padding-right: 16px;

            &.search {
              &.search-location {
                padding-left: 0;
                padding-right: 0;
              }
            }

            @media (max-width: 767px) {
              padding-left: 16px;
              padding-right: 16px;
            }

            ion-col {
              &.first {
                padding: 8px;
              }
            }

            &.side {
              padding: 0 !important;

              ion-col {
                &:first-child {
                  padding-right: 8px !important;
                }

                &:last-child {
                  padding-left: 8px !important;
                  padding-right: 8px !important;
                }
              }
            }

            &.side-mobile {
              padding: 0 !important;
            }
          }

          .masonry-item {
            transition: box-shadow .2s, transform .3s, -webkit-transform .3s;
            position: relative;
            border-radius: 4px;

            &:hover {
              box-shadow: 0 13px 20px 2px rgb(0 0 0 / 30%);
              transform: translate(0, -4px);
            }

            img {
              background-position: 50% 50%;
              background-size: cover;
              background-repeat: no-repeat;
              height: 100%;
              width: 100%;
              border-radius: 4px;
              cursor: pointer;
              object-fit: cover;
              margin-bottom: -3px;
            }

            .content-img {
              position: absolute;
              bottom: 24px;
              padding-left: 24px;
              color: var(--ion-color-light);
              cursor: pointer;


              h4 {
                margin-bottom: 5px;
              }

              p {
                margin: 0;

                font: {
                  family: $fontMedium !important;
                }
              }

              @media (max-width: 768px) {
                bottom: 16px !important;
                padding-left: 16px;

                h4 {
                  margin-bottom: 0;
                }
              }

              @media (max-width: 480px) {
                bottom: 8px !important;
                padding-left: 8px;

                h4 {
                  margin-bottom: 0;
                  line-height: 1.2 !important;
                }

                p {
                  font-size: 14px !important;
                  line-height: 16px !important;
                }
              }
            }
          }

          .main {
            transition: box-shadow .2s, transform .3s, -webkit-transform .3s;
            position: relative;
            border-radius: 4px;
            width: 100%;
            height: 100%;

            &:hover {
              box-shadow: 0 13px 20px 2px rgb(0 0 0 / 30%);
              transform: translate(0, -4px);
            }

            img {
              background-position: 50% 50%;
              background-size: cover;
              background-repeat: no-repeat;
              height: 100%;
              width: 100%;
              border-radius: 4px;
              cursor: pointer;
              object-fit: cover;
            }

            .content-img {
              position: absolute;
              bottom: 24px;
              padding-left: 24px;
              color: var(--ion-color-light);
              cursor: pointer;


              h4 {
                margin-bottom: 5px;
              }

              p {
                margin: 0;

                font: {
                  family: $fontMedium;
                }
              }

              @media (max-width: 768px) {
                bottom: 16px !important;
                padding-left: 16px;

                h4 {
                  margin-bottom: 0;
                }
              }

              @media (max-width: 480px) {
                bottom: 8px !important;
                padding-left: 8px;

                h4 {
                  margin-bottom: 0;
                  line-height: 1.2 !important;
                }

                p {
                  font-size: 14px !important;
                  line-height: 16px !important;
                }
              }
            }
          }

          &.no-padding-top {
            padding-top: 0;
          }
        }
      }
    }
  }

  .main-content {
    min-height: calc(100vh - 420px);
  }

  .header-banner-with-text-in-img {
    position: relative;

    .banner-img {
      width: 100%;
      height: unset;
      // height: 100vh;
      max-height: 90vh;
      min-height: 540px;
      // max-width: none;
      object-fit: cover;
      object-position: 50% 100%;
    }

    .content {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: auto;
      z-index: 1;
      width: 100%;
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 48px;
      padding-left: 48px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;

      .content-text {
        max-width: 1248px;
        margin: 0 auto;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 48px 24px;
        display: flex;
        align-items: center;

        .text {
          max-width: 540px;
          background: var(--ion-color-light);
          border-radius: 10px;
          padding: 24px 32px 32px 32px;

          h1 {
            margin: 0 0 12px 0;
            font-size: 58px;
            line-height: 60px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }

          @media (max-width: 1100px) {
            h1 {
              font-size: 48px;
              line-height: 50px;
            }
          }

          @media (max-width: 992px) {
            h1 {
              font-size: 38px;
              line-height: 40px;
            }
          }

          @media (max-width: 480px) {
            padding: 24px;
            h1 {
              font-size: 38px;
              line-height: 40px !important;
            }
          }

          .context-text-subtitle-container {
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }

          p {
            margin: 0;
          }

          img {
            height: 70px;
          }
        }
      }
    }
  }
}

ion-badge {
  min-width: 19px;
  border-radius: 18px;
  height: 19px;
  font-size: 11px;
  --padding-top: 4px;
  --padding-end: 6px;
  --padding-bottom: 4px;
  --padding-start: 6px;
}

ion-searchbar {
  &.sc-ion-searchbar-md-s.input-fill-outline,
  &.sc-ion-searchbar-md-h.input-fill-outline,
  &.sc-ion-searchbar-md-s,
  &.sc-ion-searchbar-md-h {
    min-height: 42px;

    --background: var(--ion-color-light);
    --highlight-color: var(--ion-input-hover-border-color);
    --highlight-color-focused: var(--ion-input-focus-border-color);
    --highlight-color-valid: var(--ion-input-border-color);
    --highlight-color-invalid: var(--ion-input-border-color);
    --box-shadow: none;

    font: {
      family: $fontMedium;
      size: 1rem;
    }

    .searchbar-input {
      border: 2px solid var(--ion-input-border-color);
      border-radius: 4px;
    }

    @media (any-hover: hover) {
      &:hover {
        .searchbar-input {
          border-color: var(--ion-input-hover-border-color);
        }
      }
    }

    &.searchbar-has-focus {
      .searchbar-input {
        border-color: var(--ion-input-focus-border-color);
      }
    }

    &[labelplacement=top] {
      --background: none;
      --padding-top: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-end: 0;

      searchbar-input {
        background: var(--ion-color-light);
        padding: 10px 12px;
      }

      .input-outline-container {
        display: none;
      }

      @media (any-hover: hover) {
        &:hover {
          input.native-input {
            border-color: var(--ion-input-hover-border-color);
          }
        }
      }

      &.searchbar-has-focus {
        input.native-input {
          border-color: var(--ion-input-focus-border-color);
        }
      }
    }
  }

  &[labelplacement=top] {
    label {
      gap: 8px;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
}

ion-toast {
  &[color="primary"] {
    &::part(button) {
      color: var(--ion-color-primary);
      background: var(--ion-color-primary-contrast);
      border: solid var(--ion-color-primary) 2px;
      font-family: var(--ion-font-family-bold);
      font-size: 16px;
      text-transform: none;
      border-radius: 50px;
      padding-top: 8px;
      padding-bottom: 8px;

      &:hover {
        color: var(--ion-color-primary-contrast);
        background: var(--ion-color-primary);
        border: solid var(--ion-color-primary-contrast) 2px;
      }
    }
  }
}

ion-item {
  --ion-item-background: transparant;

  &.image-with-title {
    position: relative;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%), 0 1px 3px 0 rgb(0 0 0 / 10%);
    transition: transform .3s, box-shadow .2s, -webkit-transform .3s;
    width: 100%;
    height: 256px;

    --padding-start: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-end: 0;

    --inner-padding-start: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
    --inner-padding-end: 0;

    &::part(native) {
      width: 100%;
      height: 100%;
    }

    img {
      -o-object-fit: cover;
      object-fit: cover;
    }

    .image-title {
      position: absolute;
      bottom: 12px;
      right: 12px;
      left: 12px;
      margin: 0;
      padding: 0;
      color: var(--ion-color-light);
      text-shadow: 0 1px 6px var(--ion-color-black);

      @media (max-width: 768px) {
        bottom: 8px;
        right: 8px;
        left: 8px;
      }
    }

    &:hover {
      box-shadow: 0 8px 20px 0 rgb(0 0 0 / 6%), 0 8px 16px 0 rgb(0 0 0 / 10%);
      transform: scale(1.035);
    }

    @media (max-width: 480px) {
      height: 224px;
    }
  }

  &.input-with-border {
    --padding-start: 8px;
    --inner-padding-end: 0;
    --padding-end: 8px;
    --inner-padding-start: 0;
    --min-height: 36px;
    --background: var(--ion-color-light);
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;

    &::part(native) {
      border: 2px solid var(--ion-input-border-color);
      border-radius: 4px;
    }

    @media (any-hover: hover) {
      &:hover {
        &::part(native) {
          border-color: var(--ion-input-hover-border-color);
        }
      }
    }

    ion-label {
      font: {
        family: var(--ion-font-family-medium);
        size: 1rem;
      }
    }
  }

  ion-label {
    &.flex-space-between {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }
  }
}

ion-checkbox {
  margin: 8px 0;

  --border-color: var(--ion-color-light-shade);
  --border-color-checked: var(--ion-color-light-shade);
  --checkbox-background: var(--ion-color-light);
  --checkbox-background-checked: var(--ion-color-light);
  --checkmark-color: var(--ion-text-color);

  font: {
    family: var(--ion-font-family-medium);
    size: 16px;
  }

  &::part(label) {
    white-space: normal;
  }

  &.label-default {
    color: var(--ion-text-color);

    font: {
      family: $fontBold;
      size: 1rem;
    }
  }

  &.label-color-medium {
    color: var(--ion-color-medium);
  }
}

ion-modal {
  --ion-background-color: var(--ion-color-light);

  ion-title {
    font-family: var(--ion-font-family-bold);
    font-size: 24px;
  }

  ion-header {
    &.header-md {
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    &.header-ios ion-toolbar:last-of-type {
      --border-width: 0;
    }

    ion-toolbar {
      --background: var(--ion-color-light);
      --border-width: 0 !important;
    }

    &.header-md::after {
      background-image: none;
    }
  }

  ion-footer {
    &.footer-md::before {
      background-image: none;
    }
  }

  &.post-details {
    .post-card {
      padding: 0;
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
  }
}

ion-accordion-group {
  ion-accordion {
    &.accordion-default {
      .ion-accordion-toggle-icon {
        color: var(--ion-text-color);
      }

      ion-item {
        --background: var(--ion-background-color);
        --border-width: 0 0 2px 0 !important;
        --border-color: #888;
        --padding-start: 0;
        --inner-padding-end: 0;

        ion-label {
          --ion-font-family: var(--ion-font-family-medium);
          font: {
            size: 1.25em !important;
          }

          --color: var(--ion-text-color);
          white-space: unset !important;

          @media (max-width: 480px) {
            font-size: 1.2em;
          }
        }

        ion-icon {
          color: var(--ion-text-color);
        }
      }

      .content {
        padding-left: 0;
        padding-right: 0;
        background: var(--ion-background-color);

        font: {
          family: $fontRegular;
          size: 1.2em;
        }

        :first-child {
          margin-top: 0;
          padding-top: 0;
        }

        @media (max-width: 480px) {
          font-size: 1.1em;
        }
      }
    }
  }
}

ion-avatar {
  position: relative;
}

ion-thumbnail {
  position: relative;
}

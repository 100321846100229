.list-item-card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: var(--ion-color-light);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  transition: transform .3s, box-shadow .2s, -webkit-transform .3s;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-start: span 1;
  grid-row-end: span 1;
  grid-column-start: span 1;
  grid-column-end: span 1;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 20px 0 rgb(0 0 0 / 6%), 0 8px 16px 0 rgb(0 0 0 / 10%);
    transform: scale(1.035);
  }

  @media (max-width: 480px) {
    height: auto;
  }

  .footer {
    padding: 8px 16px 8px 16px;
    z-index: 2;

    @media (max-width: 480px) {
      padding: 0 12px 6px 12px;
    }

    .list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .list-item {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
        border: 1px solid rgba(0, 0, 0, .05);
        border-radius: 4px;
        background-color: var(--ion-color-tertiary);
        padding: 0.33em 0.5em 0.3em;
        line-height: 1.2em;
        text-align: center;
        color: var(--ion-color-gray);
        text-decoration: none;

        font: {
          family: $fontMedium;
          size: 12px;
        }

        &:hover {
          color: black;
        }
      }
    }
  }
}

ion-card {
  &.card-group {
    margin-inline: 0 !important;
    margin: 0 !important;
    box-shadow: none;
    background: var(--ion-color-light);

    img {
      &.header-img {
        width: 100%;
        height: 256px;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        -o-object-fit: cover;
        object-fit: cover;

        @media (max-width: 480px) {
          height: 224px;
        }
      }
    }

    .icon {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .subtitle {
      justify-content: space-between;
    }

    ion-card-subtitle {
      color: var(--ion-color-gray);
      line-height: 1.4em;
      text-transform: none;

      font: {
        family: $fontRegular;
        size: 16px;
      }
    }

    ion-card-title {
      color: var(--ion-text-color);
      line-height: 1.4em;
      margin-top: 16px;

      font: {
        family: $fontBold;
        size: 1.4em;
      }
    }

    ion-card-content {
      color: var(--ion-text-color);
      line-height: 1.4em;

      font: {
        family: $fontRegular;
        size: 16px;
      }

      p {
        color: var(--ion-text-color);
        line-height: 1.4em;

        font: {
          family: $fontRegular;
          size: 16px;
        }

        strong {
          font: {
            family: $fontBold;
          }
        }

        a {
          color: var(--ion-color-primary);
        }

        &.urgent {
          color: var(--ion-color-danger);
          margin-top: 8px;
        }

        &.needs-help {
          color: var(--ion-color-success-shade);
          margin-top: 8px;
        }
      }
    }

    &.kavel {
      ion-card-content {
        padding-bottom: 18px;
      }
    }

    @media (max-width: 480px) {
      ion-card-header {
        padding: 12px 12px 6px 12px;

        ion-card-title {
          margin-top: 8px;
        }
      }

      ion-card-content {
        padding: 0 12px 12px 12px;
      }
    }
  }

  ion-card-header {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.contact-card {
  width: 100%;
  height: 100%;
  padding: 32px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 4%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
  background-color: var(--ion-color-light);

  &.job {
    height: auto;

    @media (max-width: 480px) {
      padding: 16px;
    }
  }

  ion-icon {
    font-size: 24px;
    color: var(--ion-color-primary);
  }

  h2 {
    font-size: 1.4em;
  }

  .content {
    line-height: 1.5em;
    font: {
      family: $fontMedium;
      size: 18px;
    };
  }

  .link {
    color: var(--ion-color-primary);
    text-decoration: underline;
    cursor: pointer;

    font: {
      family: $fontMedium;
    }
  }
}

.followed-card {
  width: 100%;
  height: auto;
  max-width: 420px;
  margin-bottom: 64px;
  padding: 32px;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
  background-color: var(--ion-color-light);
  text-align: center;

  .followers {
    .images {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      min-width: 64px;

      img {
        position: relative;
        left: auto;
        width: 48px;
        height: 48px;
        margin-left: -16px;
        border: 2px solid var(--ion-color-light);
        border-radius: 50%;
      }

      ion-badge {
        position: relative;
        left: auto;
        width: 48px;
        height: 48px;
        margin-left: -16px;
        border: 2px solid var(--ion-color-light);
        border-radius: 50%;
        display: flex;
        font-size: 18px;
        justify-content: center;
        align-items: center;

        span {
          font-size: 16px;
        }
      }

    }
  }
}

.news {
  .groups-card {
    margin-bottom: 32px;

    @media screen and (max-width: 576px) {
      margin-bottom: 16px;
    }
  }
}

.calendar {
  .calendar-card {
    margin-bottom: 32px;

    @media screen and (max-width: 576px) {
      margin-bottom: 16px;
    }
  }
}

.news-card {
  border-radius: 10px;
  height: 100%;
  transition: transform .3s, box-shadow .2s, -webkit-transform .3s;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  background: var(--ion-color-light);
  padding: 24px 24px 0 24px;
  margin: 0;

  &.ion-activatable {
    &:hover {
      transform: scale(1.020);

      h4 {
        text-decoration: underline;
      }
    }
  }

  .card-body {
    padding: 0 16px;
    flex-grow: 1;

    .time-and-indication-container {
      display: flex;
      // justify-content: space-between;
      gap: 16px;

      ion-icon {
        vertical-align: sub;
        font: {
          size: 18px;
        };
      }
    }

    h4 {
      font: {
        family: $fontBold;
        size: 18px;
      }
  
      color: var(--ion-color-dark);
      margin: 8px 0 0 0 !important;
    }
  }

  .img-div {
    min-width: 240px;
    max-width: 240px;
    min-height: 240px;
    max-height: 240px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  p {
    color: var(--ion-color-medium);
    margin: 0;
    font: {
      family: $fontRegular;
      size: 14px;
    }
    &.link {
      margin-bottom: 0;
      font: {
        family: $fontBold;
        size: 14px;
      }
    }
  }

  .intro {
    color: var(--ion-color-dark);
    margin: 8px 0 0 0;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font: {
      family: $fontRegular;
      size: 14px;
    }

    @media screen and (max-width: 576px) {
      -webkit-line-clamp: 4;
    }

    p {
      color: var(--ion-color-dark);
    }
  }

  .card-footer {
    border-top: 1px solid var(--ion-color-light-shade);
    margin-top: 32px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ion-item {
      --background: transparent;
      --padding-start: 0;
    }

    ion-icon {
      font-size: 32px;
      color: var(--ion-color-primary);
    }
  }

  &.calendar {
    .body-main {
      flex-direction: row-reverse;
    }

    .img-div {
      min-width: 140px;
      max-width: 140px;
      min-height: 140px;
      max-height: 140px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    h2 {
      margin: 5px 0 0 0;
    }

    .card-body {
      display: flex;
      padding: 0 32px 0 0;

      .date {
        padding-right: 64px;
      }

      p {
        &.address {
          margin-top: 5px;
        }
      }

      .intro {
        p {
          font: {
            size: 18px;
          }
        }
      }
    }
  }

  &.news-card-initiator {
    padding: 16px 0;
    border-radius: 0;
    height: 100%;
    transition: none;
    box-shadow: none;
    border-bottom: 1px solid var(--ion-color-light-shade);

    .content-in-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;

      .footer-buttons {
        margin: -8px;

        ion-button {
          --padding-start: 8px;
          --padding-end: 8px;
          height: 36px;
          margin: 0;

          ion-icon {
            margin: 0 0.3rem 0 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.calendar .body-main,
    .body-main {
      flex-direction: column;

      .img-div {
        min-width: 100%;
        max-width: 100%;
        min-height: 240px;
        max-height: 240px;
        margin-bottom: 20px;

        img {
          height: 240px;
          width: 100%;
          object-fit: cover;
        }
      }

      .card-body {
        padding: 0;

        .time-and-indication-container {
          gap: 8px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 16px 16px 12px 16px;
    .card-footer {
      margin-top: 20px;
    }

    .date {
      display: none !important;
    }
  }
}

.groups-card {
  border: 0.3px solid var(--ion-color-light-shade);
  border-radius: 6px;
  background: var(--ion-color-light);

  ion-row {
    padding: 0;

    &.content-card {
      padding: 12px;
    }
  }

  img {
    width: 100%;
    height: 160px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    object-fit: cover;
    border-bottom: 0.3px solid var(--ion-color-light-shade);
  }

  .intro {
    color: var(--ion-color-dark);
    padding-top: 5px;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;

    font: {
      family: $fontRegular;
      size: 14px;
    }

    @media screen and (max-width: 576px) {
      -webkit-line-clamp: 4;
      padding-right: 0;
    }
  }

  .btn-update {
    width: 100%;
  }
}

.calendar-card {
  border-radius: 10px;
  padding: 16px;
  background: var(--ion-color-tertiary);

  margin-bottom: 32px;

  @media screen and (max-width: 576px) {
    margin-bottom: 16px;
  }

  &.detail {
    padding: 24px;

    @media screen and (max-width: 576px) {
      padding: 12px;
    }
  }

  ion-row {
    padding: 0;

    ion-col {
      padding: 0;

      p {
        font-size: 14px;
        color: var(--ion-color-secondary);
        margin: 8px 0;
      }

      &.btn-col {
        @media screen and (max-width: 576px) {
          justify-content: flex-start;
          padding-top: 16px;
        }

        &.mobile {
          @media screen and (max-width: 960px) {
            justify-content: space-between
          }
        }
      }
    }
  }

  .intro {
    margin-top: 8px;
    color: var(--ion-color-dark);
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 24px;

    font: {
      family: $fontRegular;
      size: 14px;
    }

    @media screen and (max-width: 576px) {
      -webkit-line-clamp: 4;
    }

    p {
      margin: 0;
      font: {
        family: $fontRegular;
        size: 14px;
      }
    }
  }

  .detail-content {
    margin-top: 12px;
    color: var(--ion-color-dark);
    line-height: 16px;
    margin-bottom: 24px;

    font: {
      family: $fontRegular;
      size: 14px;
    }
  }

  .followers {
    height: 100%;

    .images {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: nowrap;

      img {
        position: relative;
        left: auto;
        width: 34px;
        margin-left: -10px;
        border: 2px solid var(--ion-color-light);
        border-radius: 50% !important;
      }
    }

    .date {
      color: var(--ion-color-medium);
      font: {
        family: $fontMedium;
        size: 12px;
      }
    }
  }

  .btn-update {
    padding: 4px 16px;
    z-index: 2;
  }

  p {
    &.link {
      margin-bottom: 8px;
      font: {
        family: $fontMedium;
        size: 16px;
      };
    }
  }
}

.post-no-member {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 36px 16px;
  background: var(--ion-color-light);
  text-align: center;
  margin-bottom: 16px;

  p {
    &.melding {
      margin: 0;
      color: var(--ion-color-medium);
      font-size: 12px;
    }

    &.anchor-buttons {
      line-height: 40px;
    }
  }
}

.chat-main {
  .post-write {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;

    border: unset !important;
    border-top: 1px solid var(--ion-color-light-shade) !important;
    border-radius: 0 0 6px 6px !important;
    box-shadow: none !important;

    ion-avatar {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.post-write {
  border-radius: 10px;
  padding: 20px 24px;
  background: var(--ion-color-light);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  &.show-post {
    box-shadow: none;
    border-radius: unset !important;
    padding: 20px 0;
    margin-top: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--ion-color-light-shade);
  
  }

  ion-avatar {
    width: 40px;
    height: 40px;

    margin-right: 12px;
  }

  .counter {
    position: absolute;
    right: 16px;
    bottom: 10px;

    font: {
      family: $fontRegular;
      size: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px;

    ion-avatar {
      width: 44px;
      height: 44px;
    }

    ion-icon {
      padding-bottom: 10px;
    }
  }
}

.concept-detail .comment-card,
.concept-detail .post-card,
.comment-card,
.post-card {
  border-radius: 10px;
  padding: 20px 24px;
  background: var(--ion-color-light);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);

  ion-row {
    padding: 0;

    ion-col {
      padding: 0;

      ion-avatar {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }

      p {
        margin: 0;

        &.date {
          color: var(--ion-color-medium);
          line-height: 14px;

          font: {
            family: $fontRegular;
            size: 12px;
          }
        }
      }

      h3 {
        margin: 0;
        color: var(--ion-color-dark);
        line-height: 18px;
        font: {
          family: $fontBold;
          size: 14px;
        }
      }

      h5 {
        margin: 0;

        font: {
          family: $fontBold;
          size: 14px;
        }

        &.comment {
          font: {
            family: $fontRegular;
            size: 14px;
          }
        }

        ion-icon {
          font-size: 16px !important;
          vertical-align: text-bottom;
        }
      }
    }

    &.foot {
      border-top: 1px solid var(--ion-color-light-shade);
      padding-top: 16px;
      margin-top: 8px;
      ion-icon {
        font-size: 24px;
      }

      .popover {
        cursor: pointer;
        padding-bottom: 8px;
        z-index: 2;
      }
    }
  }

  .content {
    margin: 16px 0 18px 0;
    color: var(--ion-color-dark);
    line-height: 24px;

    font: {
      family: $fontMedium;
      size: 20px !important;
    }

    p {
      font: {
        family: $fontMedium;
        size: 20px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px 8px;

    ion-avatar {
      width: 44px !important;
      height: 44px !important;
    }

    .content {
      font: {
        size: 16px !important;
      }
  
      margin: 16px 0 18px 0;
      color: var(--ion-color-dark);
      line-height: 20px;
  
      p {
        font: {
          size: 16px !important;
        }
      }
    }
  }
}

.concept-detail .comment-card,
.comment-card {
  box-shadow: none;
  border-radius: 6px;
  background: #F2F2F2;
  margin-bottom: 20px;

  .foot {
    border-top: 1px solid var(--ion-color-medium-shade);
  }

}

.show-post {
  .comment-card,
  .post-card {
    .content {
      text-decoration: none !important;
    }
  }
}

ion-button {
  &.ellipsis {
    height: 20px;
    margin: 0;
    --padding-end: 3px;
    --padding-start: 3px;
  }
}

.account-group {
  h1 {
    font-size: 21px !important;
    line-height: 30px !important;
  }

  h2 {
    font-size: 18px !important;
    line-height: 24px !important;
    margin-bottom: 0;
  }

  p {
    &.subtitle {
      margin-top: 0 !important;
      font-size: 20px;
    }

    &.slide-content {
      margin-top: 0 !important;
      font-size: 18px;
    }
  }
  hr {
    margin: 24px 0 20px 0;

    @media (max-width: 480px) {
      margin: 18px 0 12px 0;
    }
  }

  .ion-gap-24 {
    gap: 24px;

    @media (max-width: 992px) {
      gap: 12px;
    }
  }

  .account-group-buttons {
    ion-button {
      margin: 0;
      height: 30px;
    }
  }

  app-breadcrumbs {
    ion-breadcrumbs {
      margin-bottom: 15px;
    }
  }
}

.account-group-card {
  background: var(--ion-color-light);
  padding: 24px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 0 24px 0;

  @media (max-width: 480px) {
    padding: 12px;
  }

  img {
    border-radius: 4px;
    object-fit: cover;
    height: 182px;

    @media (max-width: 768px) {
      height: 240px;
    }
  }

  .card-messages {
    margin-right: 12px;
    margin-top: 8px;
    cursor: pointer;
    ion-icon {
      font-size: 24px;
      color: var(--ion-color-dark);
    }

    .badge {
      top: -8px;
      right: -13px;
    }
  }

  ion-col {
    &.card-content {
      padding: 0 0 0 20px;

      .content-header {
        padding: 0;
          position: relative;
          width: 100%;
          height: 100%;
          grid-auto-columns: 1fr;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          grid-row-start: span 1;
          grid-row-end: span 1;
          grid-column-start: span 1;
          grid-column-end: span 1;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
      }

      @media (max-width: 767px) {
        padding: 20px 0 0 0;
      }

      ion-col {
        &.card-header {
          h2 {
            margin: 0 0 4px 0;
            line-height: 42px;
            color: rgba(0, 0, 0, 0.9);

            font: {
              family: $fontBold;
              size: 32px;
            }

            @media (max-width: 992px) {
              font-size: 24px;
            }
          }

          h3 {
            margin: 0;
            color: var(--ion-color-medium);
            line-height: 23px;

            font: {
              family: $fontRegular;
              size: 16px;
            }
          }
        }

        &.child-cards {
          ion-row {
            ion-col {
              &.toggle {
                padding: 0;
              }
            }

            .child-cards-item {
              
              p {
                font-size: 14px;
                margin: 0;
                line-height: 16px;
                color: var(--ion-color-medium);
                margin-left: 8px;

                span {
                  line-height: 16px;
                  color: var(--ion-color-medium);

                  font: {
                    family: $fontBold;
                    size: 16px;
                  }
                }

                a {
                  color: rgba(0, 0, 0, 0.6);

                  &:hover {
                    color: var(--ion-color-primary);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.initiator {
    padding: 24px 24px 20px 24px;

    @media (max-width: 480px) {
      padding: 12px 12px 10px 12px;
    }

    .card-header {
      &.ion-flex {
        flex-wrap: wrap !important;
        gap: 24px;
      }
    }

    hr {
      margin: 24px 0;
    }

    .edit-links {
      ion-item {
        cursor: pointer;
        --min-height: 30px;
        --padding-start: 0;
        --inner-padding-end: 0;
        --background: var(--ion-color-light);
        margin-bottom: 4px;
        ion-icon {
          color: var(--ion-color-gray);
          margin: 0;
        }

        ion-label {
          --color: var(--ion-color-gray);
          font: {
            family: $fontBold !important;
            size: 16px;
          }

          line-height: 24px;
          margin: 0 0 0 12px;
        }

        &:hover {
          ion-icon {
            color: rgba(0, 0, 0, 0.8);
          }

          ion-label {
            --color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }

    .badge-btn-links {
      flex-wrap: wrap;
      gap: 24px;

      ion-item {
        --background: transparent;
        --background-hover: transparent;
        --min-height: 30px;
        --padding-start: 0;
        --inner-padding-end: 0;

        ion-label {
          --color: var(--ion-color-gray);
          font: {
            family: $fontBold !important;
            size: 16px;
          }

          line-height: 24px;
          margin: 0;
        }

        ion-badge {
          margin-left: 12px;
        }

        &:hover {
          ion-label {
            --color: rgba(0, 0, 0, 0.8);
          }
        }
      }

      @media (max-width: 480px) {
        gap: 8px;
        display: block;

        ion-item {
          width: fit-content;
          margin-bottom: 5px;
        }
      }
    }

    ion-list {
      &.group-initiator-list {
        background: none;

        ion-item {
          --background: none;
          --inner-padding-end: 10px;

          ion-avatar {
            height: 42px;
            width: 42px;
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          ion-label {
            color: var(--ion-text-color);
            flex: unset;
            width: fit-content;
            border-bottom: 1px solid transparent;

            h4 {
              font: {
                family: $fontBold;
                size: 16px;
              }
            }
          }

          ion-button {
            margin-left: 4px;
            font: {
              size: 15px;
            }
          }

          ion-select {
            flex: 1 1 0;
          }

          .action-status {
            color: var(--ion-color-gray);
            font: {
              family: $fontSemiBold;
              size: 16px;
              weight: $normal-weight;
            }

            &.register {
              color: var(--ion-color-primary);
            }
          }

          ion-button.action-status {
            text-transform: none;
            height: 2.2em;
          }

          &:hover {
            .action-status {
              color: var(--ion-color-secondary);

              &.register {
                color: var(--ion-color-primary);
              }
            }
          }

          @media (max-width: 480px) {
            --padding-start: 0;
            --padding-end: 0;
            --inner-padding-end: 0;
            margin: 3px 0;
            border-bottom: 1px solid var(--ion-color-light-shade);

            &::part(native) {
              flex-direction: column;
            }

            h2 {
              font: {
                size: 14px!important;
              };
            }

            ion-avatar {
              margin-right: 8px;
            }

            ion-label {
              font-size: 14px;
            }

            .action-status {
              font-size: 14px;
            }

            .new-group-select-list {
              ion-item {
                margin: 0;
                --padding-start: 8px;
              }
            }

            ion-select {
              margin: 0 0 16px 0;
            }

            ion-list {
              width: 100%;
            }

            ion-button {
              margin: 0 0 16px 0;
            }
          }
        }
      }
    }
  }
}

.account-notification-card {
  background: var(--ion-color-light);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 0 24px 0;
  padding: 20px 30px 10px 30px;

  .date {
    color: rgba(0, 0, 0, 0.8);
    line-height: 21px;

    font: {
      family: $fontBold;
      size: 18px;
    }
  }

  @media (max-width: 480px) {
    padding: 12px 16px 10px 16px;
    margin: 0 0 24px 0;

    .date {
      font-size: 16px;
    }
  }

  ion-item {
    --padding-start: 0;
    --background: var(--ion-color-light);
    --inner-padding-start: 25px;
    --inner-padding-end: 0px;
    display: flex;
    align-items: flex-start;

    ion-avatar {
      width: 54px;
      height: 54px;
      margin: 24px 0 24px 0;
      display: flex;
      align-items: flex-start;
    }

    &:last-child {
      --border-color: transparent;
    }

    ion-label {
      margin-top: 40px;
      white-space: normal !important;

      font: {
        family: $fontRegular;
        size: 16px !important;
      }

      span {
        font: {
          family: $fontSemiBold;
        }
      }
    }

    @media (max-width: 480px) {
      --inner-padding-start: 16px;

      ion-avatar {
        width: 44px;
        height: 44px;
      }

      ion-label {
        margin-top: 30px;
        font-size: 14px !important;
      }
    }
  }
}
